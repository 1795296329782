<template>
	<div>
		<skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

		<div v-else>
			<div v-if="mcrType == 'B3a' || mcrType == 'B3b' || mcrType == 'B4' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3'">
				<label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
		        <base-input :name="tt('material_number')" rules="required">
		            <el-select v-model="input.material_number" :placeholder="tt('choose_material_number')" :remote-method="querySearchMaterialNumber" :loading="selectSearch.matnumLoading" filterable remote reserve-keyword :disabled="formType == 'edit' || formType == 'detail' || mcrType == 'A2a' || mcrType == 'A2b' || mcrType == 'A3'">
		                <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
		            </el-select>
		        </base-input>
			</div>

			<!-- BASIC DATA SPECIFICATION -->

			<div id="accordionBds" v-if="mcrType != 'B3b' && mcrType != 'A2b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          	<a href="javascript:void(0)" @click="toggleAccordion(accordion.bds.show, 'bds')">
				          		<b>{{ tt("basic_data_&_specification") }}</b>
				            	<span class="text-danger"> *</span>
				        	</a>
				        </h5>
				      </div>
				    </div>
				  </div>
				  <div id="collapseBds" :class="'collapse ' + accordion.bds.show">
				    <div class="card-body border">
				    	<div class="row">
							<div class="col-md-9">
								<label class="form-control-label">{{ tt('item_name') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('item_name')" rules="required">
								    <el-select v-model="input.prc_inc" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchItemName" :loading="selectSearch.sitLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								        <el-option v-for="si in standardItemName" :key="si.inc" :label="si.inc +' - '+ si.item_name" :value="si.inc"></el-option>
								    </el-select>
								</base-input>
							</div>

							<div class="col-md-3">
								<label class="form-control-label">{{ tt('item_type') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('item_type')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_item_type_code" :placeholder="tt('choose_item_type')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('group_class_or_material_group') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('group_class')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_nsc" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchGroupClass" :loading="selectSearch.ngcLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @change="changeGroupClass">
								    <el-option class="select-danger" :value="ngc.nsc" :label="ngc.nsc +' - '+ ngc.description" :key="ngc.nsc" v-for="ngc in  natoGroupClass"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('scope_of_supply') }}</label>
								<!-- <base-input :name="tt('scope_of_supply')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_sos_header" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchSos" :loading="selectSearch.sosLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="sos.sos_header" :label="sos.sos_header +' - '+ sos.sos_description" :key="sos.sos_header" v-for="sos in  scopeOfSupply"></el-option>
								  </el-select>
								</base-input> -->
								<base-input :name="tt('scope_of_supply')">
								  <el-select class="select-danger" v-model="input.prc_sos_header" :placeholder="tt('scope_of_supply')" disabled>
								    <el-option class="select-danger" :value="sos.sos_header" :label="sos.sos_header +' - '+ sos.sos_description" :key="sos.sos_header" v-for="sos in  scopeOfSupply"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('uom') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('uom')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_uom_code" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchUom" :loading="selectSearch.uomLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="uom.code" :label="uom.code+' - '+uom.description" :key="uom.code" v-for="uom in  unitOfMeasure"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('material_type') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('material_type')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_mtype_code" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchMtype" :loading="selectSearch.mtLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="mt.code" :label="mt.code+' - '+mt.description" :key="mt.code" v-for="mt in  materialType"></el-option>
								  </el-select>
								</base-input>
							</div>

							<!-- <div class="col-md-6">
								<label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('catalog_type')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_catalog_type_code" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchCtype" :loading="selectSearch.ctLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="ct.code" :label="ct.code +' - '+ ct.description" :key="ct.code" v-for="ct in  catalogType"></el-option>
								  </el-select>
								</base-input>
							</div> -->
				        
							<!-- <div class="col-md-6">
								<label class="form-control-label">{{ tt('material_group') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('material_group')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_mgroup_code" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchMgroup" :loading="selectSearch.mgLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="mg.code" :label="mg.code +' - '+ mg.description" :key="mg.code" v-for="mg in  materialGroup"></el-option>
								  </el-select>
								</base-input>
							</div> -->

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('fabric_or_non_fabrication') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('fabric_or_non_fabrication')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
								  </el-select>
								</base-input>
							</div>
				        
							<div class="col-md-6">
								<label class="form-control-label">{{ tt('bom_or_non_bom') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('bom_or_non_bom')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
								    <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
								  </el-select>
								</base-input>
							</div>
				       
				        	<div class="col-md-6">
					            <label class="form-control-label">{{ tt('critical_non_critical') }} <span class="text-danger">*</span></label>
					            <base-input :name="tt('critical_non_critical')" rules="required">
					              <el-select class="select-danger" v-model="input.prc_critical_non_critical" :placeholder="tt('choose_critical_non_critical')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
					                <el-option class="select-danger" :value="cnc" :label="cnc" :key="cnc" v-for="cnc in  criticalNonCritical"></el-option>
					              </el-select>
					            </base-input>
					        </div>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- CHARACTERISTIC & VALUE -->

			<div id="accordionCharValue" v-if="mcrType != 'B3b' && mcrType != 'A2b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.charValue.show, 'charValue')">
				            <b>{{ tt("characteristic_value") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
				      	  <!-- REFRESH CHARACTERISTIC & VALUE -->
			              <base-button size="sm" type="default" :disabled="btnShowCharValue.onLoading" @click="showCharValue" v-if="!form.disabled && (mcrType == 'B2' || mcrType == 'B3a' || mcrType == 'A1' || mcrType == 'A2a')">
			              	<span v-if="btnShowCharValue.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
			          		<span v-else>{{ tt('refresh') }}</span>
			          	  </base-button>

			          	  <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseCharValue" :class="'collapse ' + accordion.charValue.show">
				    <div class="card-body border">
				    	<table class="table align-items-center table-flush table-bordered">
				          <thead class="card-header bg-primary">
				            <tr>
				              <th class="text-white">{{tt('characteristic')}}</th>
				              <th class="text-white">{{tt('value')}}</th>
				              <th width="10" class="text-white">{{tt('view')}}</th>
				              <th width="10"></th>
				            </tr>
				          </thead>
				          <tbody>
				            <tr v-for="(dinc, key) in prcMcrItemCharValue.cin_code">
				              <td>
				                {{prcMcrItemCharValue.characteristic[key]}}
				                <input type="hidden" v-model="prcMcrItemCharValue.cin_code[key]">
				              </td>
				              <td>
				                <el-autocomplete 
				                  v-model="prcMcrItemCharValue.value[key]"
				                  :fetch-suggestions="getValue"
				                  :trigger-on-focus="false"
				                  @focus="selectedCinCode(prcMcrItemCharValue.cin_code[key])"
				                  size="small"
				                  :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                </el-autocomplete>
				              </td>
				              <td><input type="checkbox" name="check" v-model="prcMcrItemCharValue.view[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"/></td>
				              <td>
				                <span v-if="mcrType == 'B2' || mcrType == 'B3a' || mcrType == 'A2a'">
				                	<button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-if="prcMcrItemCharValue.multipleChar[key] == 2 && prcMcrItemCharValue.code[key] == ''">
					                  <i class="fa fa-plus-square" aria-hidden="true" style="font-size: 15px" @click="addMultipleChar(key, prcMcrItemCharValue.cin_code[key], prcMcrItemCharValue.characteristic[key], prcMcrItemCharValue.view[key])" v-if="!form.disabled"></i>
					                </button>

					                <button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-else-if="prcMcrItemCharValue.multipleChar[key] == '' || prcMcrItemCharValue.code[key] == 1">
					                  <i class="fa fa-minus-square text-danger" aria-hidden="true" style="font-size: 15px" @click="removeMultipleChar(key)" v-if="!form.disabled"></i>
					                </button>
				                </span>

				                <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
				              </td>
				            </tr>
				          </tbody>
				        </table>
				    </div>
				  </div>
				</div>
			</div>
	        
	        <div v-if="mcrType != 'B3b' && mcrType != 'A2b'">
		        <label class="form-control-label">
		        	{{ tt('po_text_memo') }} 
		        	<!-- <span class="text-danger">*</span> -->
		        </label>
		        <base-input :name="tt('po_text_memo')">
		          <textarea v-model="input.prc_po_text_memo" class="form-control" rows="5" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"></textarea>
		        </base-input>
		    </div>

	        <!-- MANUFACTURER PART NUMBER -->

	        <div id="accordionManref" v-if="mcrType != 'B3b' && mcrType != 'A2b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.manref.show, 'manref')">
				            <b>{{ tt("manufacturer_part_number") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addMpn" v-if="!form.disabled && (mcrType == 'A1' || mcrType == 'A2a' || mcrType == 'B2' || mcrType == 'B3a')">{{ tt('add_new') }}</base-button>
			              <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseManref" :class="'collapse ' + accordion.manref.show">
				    <div class="card-body border">
				    	<table class="table align-items-center table-flush table-bordered">
				          <thead class="card-header bg-primary">
				            <tr>
				              <th class="text-white">{{tt('manufacturer_code')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('source_type')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('manufacture_ref')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('type')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('note')}}</th>
				              <th class="text-white">{{tt('status')}}</th>
				              <th></th>
				            </tr>
				          </thead>
				          <tbody>
				            <tr v-for="(mpn, key) in prcMcrItemMpn.manufacture_code"
				              v-bind:key="mpn.manufacture_code">
				              <td>
				                <el-select v-model="prcMcrItemMpn.manufacture_code[key]" :placeholder="tt('minimum_type_char', {total: 5})" :remote-method="querySearchMancode" :loading="selectSearch.mancodeLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                  <el-option :label="m.manufacture_name" :value="m.manufacture_code" v-for="m in masterManufacture" :key="m.id"></el-option>
				                </el-select>
				              </td>
				              <td>
				              	<select class="form-control form-control-sm" v-model="prcMcrItemMpn.source_type[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                  <option value="">Choose Source Type</option>
				                  <option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{st.code +' - '+ st.description}}</option>
				                </select>
				              </td>
				              <td>
				              	<!-- <input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')"
				                  v-model="prcMcrItemMpn.mpn[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"> -->
				                <div class="input-group">
								  <div class="input-group-prepend">
								    <span class="input-group-text form-control-sm">{{ prcMcrItemMpn.source_type[key] + ':' }}</span>
								  </div>
								  <input type="text" class="form-control form-control-sm" :placeholder="tt('manufacture_ref')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" v-model="prcMcrItemMpn.mpn[key]">
								</div>
				              </td>
				              <td>
				                <select class="form-control form-control-sm" v-model="prcMcrItemMpn.manufacture_type[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                  <option value="">Choose Type</option>
				                  <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.code +' - '+ mt.description}}</option>
				                </select>
				              </td>
				              <td>
				              	<input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')"
				                  v-model="prcMcrItemMpn.manufacture_note[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				              </td>
				              <td>
				              	<label class="badge badge-warning" v-if="prcMcrItemMpn.status[key] == 'old'">{{ prcMcrItemMpn.status[key] }}</label>
				              	<label class="badge badge-success" v-if="prcMcrItemMpn.status[key] == 'new'">{{ prcMcrItemMpn.status[key] }}</label>
				              </td>
				              <td width="10">
				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-if="mcrType == 'B2' && !form.disabled"></i>
				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-else-if="mcrType == 'B3a' && !form.disabled && prcMcrItemMpn.status[key] == 'new'"></i>
				              	<span v-else-if="mcrType == 'B3a' && prcMcrItemMpn.status[key] == 'old'"></span>

				              	<!-- APPROVAL PAGE -->
				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>

				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-else-if="formType == 'approval' && mcrType == 'A2a' && !form.disabled && prcMcrItemMpn.status[key] == 'new'"></i>
				              	<span v-else-if="formType == 'approval' && mcrType == 'A2a' && prcMcrItemMpn.status[key] == 'old'"></span>

				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemMpn.status[key] == 'new'"></i>
				              	<span v-else-if="formType == 'approval' && mcrType == 'A3' && prcMcrItemMpn.status[key] == 'old'"></span>
				              </td>
				            </tr>
				          </tbody>
				        </table>
				    </div>
				  </div>
				</div>
			</div>

			<!-- PLANT -->

			<div id="accordionPlant" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.plant.show, 'plant')">
				            <b>{{ tt("plant") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addPlant" v-if="mcrType == 'B2' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addPlant" v-else-if="mcrType == 'B4' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addPlant" v-else-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addPlant" v-else-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapsePlant" :class="'collapse ' + accordion.plant.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white" width="10">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(pl, key) in prcMcrItemPlant.plant" v-bind:key="pl.plant">
				                <td>
				                  <el-select style="width: 100%" v-model="prcMcrItemPlant.plant[key]" :placeholder="tt('minimum_type_char', {total: 2})" :remote-method="querySearchPlant" :loading="selectSearch.plantLoading" filterable remote reserve-keyword :disabled="prcMcrItemPlant.status[key] == 'old' || form.disabled">
									<el-option :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code" v-for="pc in plantCode" :key="pc.id" :disabled="(pc.material_number != null) ? true : false"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemPlant.status[key] == 'old'">{{ prcMcrItemPlant.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemPlant.status[key] == 'new'">{{ prcMcrItemPlant.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removePlant(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

				                	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

				                	<i class="fa fa-trash text-danger" @click="removePlant(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemPlant.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemPlant.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removePlant(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removePlant(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemPlant.status[key] == 'new'"></i>
					              	<span v-else-if="formType == 'approval' && mcrType == 'A3' && prcMcrItemPlant.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- STORAGE LOCATION -->

			<div id="accordionSloc" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.sloc.show, 'sloc')">
				            <b>{{ tt("storage_location") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addSloc" v-if="mcrType == 'B2' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addSloc" v-if="mcrType == 'B4' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addSloc" v-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addSloc" v-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseSloc" :class="'collapse ' + accordion.sloc.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
	                        <table class="table align-items-center table-flush table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
	                                    <th class="text-white">{{tt('storage_location')}}<span class="text-danger"> *</span></th>
	                                    <!-- <th class="text-white">{{tt('warehouse_number')}}</th> -->
	                                    <!-- <th class="text-white">{{tt('storage_type')}}</th> -->
	                                    <th class="text-white">{{tt('bin_location')}}</th>
	                                    <th class="text-white" width="10">{{tt('status')}}</th>
	                                    <th></th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(sloc, key) in prcMcrItemSloc.storage_location" v-bind:key="sloc.storage_location">
	                                    <td>
	                                        <select class="form-control form-control-sm" v-model="prcMcrItemSloc.plant_code[key]" :disabled="prcMcrItemSloc.status[key] == 'old' || form.disabled">
	                                            <option value="">Choose Plant</option>
	                                            <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
	                                                {{pc}}
	                                            </option>
	                                        </select>
	                                    </td>
	                                    <td>
	                                        <!-- <el-select v-model="prcMcrItemSloc.storage_location[key]" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchSloc" :loading="selectSearch.slocLoading" filterable remote reserve-keyword :disabled="form.disabled">
												<el-option :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code" v-for="lc in locationCode" :key="lc.id"></el-option>
											</el-select> -->
											<select class="form-control form-control-sm" v-model="prcMcrItemSloc.storage_location[key]" :disabled="form.disabled || (prcMcrItemSloc.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
							                    <option value="">{{tt('choose_storage_location')}}</option>
							                    <option :value="lc.location_code" v-for="lc in locationCode[prcMcrItemSloc.plant_code[key]]" v-bind:key="'sloc' + lc.id">
							                      {{lc.location_code +' - '+ lc.location_description}}</option>
							                </select>
	                                    </td>
	                                    <!-- <td>
	                                        <select class="form-control form-control-sm" 
	                                        :disabled="prcMcrItemSloc.hapus[key]=== '1'" v-model="prcMcrItemSloc.warehouse_number[key]">
	                                            <option value="">Choose WareHouse</option>
	                                            <option :value="wh.warehouse_number" v-for="wh in warehouse" v-bind:key="wh.warehouse_number">
	                                                {{wh.warehouse_description}}</option>
	                                        </select>
	                                    </td>
	                                    <td>
	                                        <select class="form-control form-control-sm" 
	                                        :disabled="prcMcrItemSloc.hapus[key]=== '1'" v-model="prcMcrItemSloc.storage_type[key]">
	                                            <option value="">Choose Storage Type</option>
	                                            <option :value="st.storage_type_code" v-for="st in storageType" v-bind:key="st.storage_type_code">
	                                                {{st.storage_type_name}}</option>
	                                        </select>
	                                    </td> -->
	                                    <td>
	                                        <!-- <el-select v-model="prcMcrItemSloc.bin_location[key]" :placeholder="tt('choose_bin_location')" :remote-method="querySearchBinloc" :loading="selectSearch.binlocLoading" filterable remote reserve-keyword :disabled="form.disabled">
												<el-option :label="bl.bin_code +' - '+ bl.bin_name" :value="bl.bin_code" v-for="bl in binloc" :key="bl.id"></el-option>
											</el-select> -->
											<input class="form-control form-control-sm" :name="tt('bin_location')" :placeholder="tt('bin_location')" v-model="prcMcrItemSloc.bin_location[key]" rules="required" :disabled="form.disabled || (prcMcrItemSloc.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
	                                    </td>
	                                    <td>
	                                    	<label class="badge badge-warning" v-if="prcMcrItemSloc.status[key] == 'old'">{{ prcMcrItemSloc.status[key] }}</label>
							              	<label class="badge badge-success" v-if="prcMcrItemSloc.status[key] == 'new'">{{ prcMcrItemSloc.status[key] }}</label>
	                                    </td>
	                                    <td width="10">
	                                    	<i class="fa fa-trash text-danger" @click="removeSloc(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

							              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

							              	<i class="fa fa-trash text-danger" @click="removeSloc(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemSloc.status[key] == 'new'"></i>
						                	<span v-else-if="mcrType == 'B4' && prcMcrItemSloc.status[key] == 'old'"></span>

						                	<!-- APPROVAL PAGE -->
							              	<i class="fa fa-trash text-danger" @click="removeSloc(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
							              	<i class="fa fa-trash text-danger" @click="removeSloc(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemSloc.status[key] == 'new'"></i>
							              	<span v-else-if="mcrType == 'A3' && prcMcrItemSloc.status[key] == 'old'"></span>
	                                    </td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- SALES -->

	        <div id="accordionSales" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.sales.show, 'sales')">
				            <b>{{ tt("sales") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addSales" v-if="(mcrType == 'B2' || mcrType == 'B4') && !form.disabled">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addSales" v-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addSales" v-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseSales" :class="'collapse ' + accordion.sales.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <!-- <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th> -->
				                <th class="text-white">{{tt('sales_org')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('distr_channel')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('sales_unit')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('division')}}<span class="text-danger"> *</span></th>
				                <!-- <th class="text-white">{{tt('tax_cat')}}<span class="text-danger"> *</span></th> -->
				                <th class="text-white">{{tt('tax_class')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('gen_item_cat_grp')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('account_assign_grp')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('item_cat_grp')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('trans_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('loading_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('profit_center')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(s, key) in prcMcrItemSales.sales_org" v-bind:key="s.sales_org">
				                <!-- <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.sls_plant_code[key]" :disabled="prcMcrItemSales.status[key] == 'old' || form.disabled" style="width: 100px">
				                    <option value="">Choose Plant</option>
				                    <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
	                                    {{pc}}
	                                </option>
				                  </select>
				                </td> -->
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.sales_org[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Sales Organization</option>
				                    <option :value="so.sales_org_code" v-for="so in salesOrganization" v-bind:key="'sales' + so.sales_org_code">{{so.sales_org_description}}</option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.distr_channel[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Distribution Channel</option>
				                    <option :value="dc.distr_channel_code" v-for="dc in distributionChannel" v-bind:key="'sales' + dc.distr_channel_code">{{dc.distr_channel_description}}</option>
				                  </select>
				                </td>
				                <td>
					              	<input class="form-control form-control-sm" :name="tt('sales_unit')" :placeholder="tt('sales_unit')" v-model="prcMcrItemSales.sales_unit[key]" rules="required" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
					            </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.division[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Division</option>
				                    <option :value="d.division_code" v-for="d in division" v-bind:key="'sales' + d.division_code">
				                      {{d.division_desc}}</option>
				                  </select>
				                </td>
				                <!-- <td><input class="form-control form-control-sm" :name="tt('tax_cat')"
				                    :placeholder="tt('tax_cat')" v-model="prcMcrItemSales.tax_cat[key]" rules="required"></td> -->
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.tax_class[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Tax Classification</option>
				                    <option :value="tc.tax_class_code" v-for="tc in taxClassification" v-bind:key="'sales' + tc.id">{{tc.tax_class_desc}}</option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.gen_item_cat_grp[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Item Category Group</option>
				                    <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="'sales' + icg.item_cat_group_code">
				                    	{{icg.item_cat_group_code +' - '+ icg.item_cat_group_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.account_assign_grp[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Account Assignment Group</option>
				                    <option :value="aag.acc_ass_group_code" v-for="aag in accountAssignmentGroup" v-bind:key="'sales' + aag.acc_ass_group_code">
				                    	{{aag.acc_ass_group_code +' - '+ aag.acc_ass_group_description}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.item_cat_grp[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Item Category Group</option>
				                    <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="'sales' + icg.item_cat_group_code">
				                    	{{icg.item_cat_group_code +' - '+ icg.item_cat_group_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.trans_group[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Transportation Group</option>
				                    <option :value="tg.trans_group_code" v-for="tg in transportationGroup" v-bind:key="'sales' + tg.trans_group_code">
				                    	{{tg.trans_group_code +' - '+ tg.trans_group_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemSales.loading_group[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Loading Group</option>
				                    <option :value="lg.loading_group_code" v-for="lg in loadingGroup" v-bind:key="'sales' + lg.loading_group_code">
				                    	{{lg.loading_group_code +' - '+ lg.loading_group_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSales.profit_center[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchProfitCenter" :loading="selectSearch.pcenterLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
									<el-option :label="pc.profit_center_code +' - '+ pc.profit_center_description" :value="pc.profit_center_code" v-for="pc in profitCenter" :key="'sales' + pc.id"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemSales.status[key] == 'old'">{{ prcMcrItemSales.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemSales.status[key] == 'new'">{{ prcMcrItemSales.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeSales(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

					              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

					              	<i class="fa fa-trash text-danger" @click="removeSales(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemSales.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemSales.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removeSales(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removeSales(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemSales.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'A3' && prcMcrItemSales.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- MRP -->

			<div id="accordionMrp" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.mrp.show, 'mrp')">
				            <b>{{ tt("mrp") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addMrp" v-if="(mcrType == 'B2' || mcrType == 'B4') && !form.disabled">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addMrp" v-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addMrp" v-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseMrp" :class="'collapse ' + accordion.mrp.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('abc_indicator')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('mrp_type')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('mrp_controller')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('reorder_point')}}</th>
				                <th class="text-white">{{tt('lot_size')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('min')}}</th>
				                <th class="text-white">{{tt('max')}}</th>
				                <th class="text-white">{{tt('procurement_type')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('special_proc')}}</th>
				                <th class="text-white">{{tt('max_stock')}}</th>
				                <th class="text-white">{{tt('fixed_lot')}}</th>
				                <!-- <th class="text-white">{{tt('ord_costs')}}</th> -->
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(mrp, key) in prcMcrItemMrp.abc_indicator" v-bind:key="mrp.abc_indicator">
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.plant_mrp[key]" :disabled="prcMcrItemMrp.status[key] == 'old' || form.disabled" style="width: 100px">
				                    <option value="">Choose Plant</option>
				                    <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
		                                {{pc}}
		                            </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.abc_indicator[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose ABC Indicator</option>
				                    <option :value="ai.abc_indicator_code" v-for="ai in abcIndicator" v-bind:key="ai.abc_indicator_code">
				                    	{{ai.abc_indicator_code +' - '+ ai.abc_indicator_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.mrp_type[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose MRP Type</option>
				                    <option :value="mt.mrp_type_code" v-for="mt in mrpTypes" v-bind:key="mt.mrp_type_code">
				                      {{mt.mrp_type_code +' - '+ mt.mrp_type_desc}}</option>
				                  </select>
				                </td>
				                <td>
				                  <!-- <el-select v-model="prcMcrItemMrp.mrp_controller[key]" :placeholder="tt('choose_mrp_controller')" :remote-method="querySearchMrpController" :loading="selectSearch.mrpControllerLoading" filterable remote reserve-keyword :disabled="form.disabled">
									<el-option :label="mc.mrp_controller_code +' - '+ mc.mrp_controller_desc" :value="mc.mrp_controller_code" v-for="mc in mrpController" :key="'mrp' + mc.id"></el-option>
								  </el-select> -->
								  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.mrp_controller[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose MRP Controller</option>
				                    <option :value="mc.mrp_controller_code" v-for="mc in mrpController[prcMcrItemMrp.plant_mrp[key]]" v-bind:key="mc.mrp_controller_code">
				                      {{mc.mrp_controller_code +' - '+ mc.mrp_controller_desc}}</option>
				                  </select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('reorder_point')" :placeholder="tt('reorder_point')" v-model="prcMcrItemMrp.reorder_point[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.lot_size[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Lot Size</option>
				                    <option :value="ls.lot_size_code" v-for="ls in lotSize" v-bind:key="ls.lot_size_code">
				                      {{ls.lot_size_code +' - '+ ls.lot_size_desc}}</option>
				                  </select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('min')" :placeholder="tt('min')" v-model="prcMcrItemMrp.min[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" style="width: 50px">
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('max')" :placeholder="tt('max')" v-model="prcMcrItemMrp.max[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" style="width: 50px">
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.procurement_type[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Procurement Type</option>
				                    <option :value="pt.proc_type_code" v-for="pt in procurementType" v-bind:key="pt.proc_type_code">
				                    	{{pt.proc_type_code +' - 	'+ pt.proc_type_desc}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <!-- <el-select v-model="prcMcrItemMrp.special_proc[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchSpcProcType" :loading="selectSearch.spcProcTypeLoading" filterable remote reserve-keyword :disabled="form.disabled">
									<el-option :label="spt.spc_proc_type_code +' - '+ spt.spc_proc_type_desc" :value="spt.spc_proc_type_code" v-for="spt in specialProcurementType" :key="'mrp' + spt.id"></el-option>
								  </el-select> -->
								  <select class="form-control form-control-sm" v-model="prcMcrItemMrp.special_proc[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">{{tt('choose_special_proc')}}</option>
				                    <option :value="spt.spc_proc_type_code" v-for="spt in specialProcurementType[prcMcrItemMrp.plant_mrp[key]]" v-bind:key="'mrp' + spt.id">
				                      {{spt.spc_proc_type_code +' - '+ spt.spc_proc_type_desc}}</option>
					              </select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('max_stock')" :placeholder="tt('max_stock')" v-model="prcMcrItemMrp.max_stock[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('fixed_lot')" :placeholder="tt('fixed_lot')" v-model="prcMcrItemMrp.fixed_lot[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <!-- <td>
				                	<input class="form-control form-control-sm" :name="tt('ord_costs')" :placeholder="tt('ord_costs')" v-model="prcMcrItemMrp.ord_costs[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td> -->
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemMrp.status[key] == 'old'">{{ prcMcrItemMrp.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemMrp.status[key] == 'new'">{{ prcMcrItemMrp.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeMrp(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

					              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

					              	<i class="fa fa-trash text-danger" @click="removeMrp(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemMrp.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemMrp.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removeMrp(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removeMrp(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemMrp.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'A3' && prcMcrItemMrp.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>
	        
	        <!-- ACCOUNTING -->

	        <div id="accordionAccounting" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.accounting.show, 'accounting')">
				            <b>{{ tt("accounting") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addAccounting" v-if="(mcrType == 'B2' || mcrType == 'B4') && !form.disabled">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addAccounting" v-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addAccounting" v-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseAccounting" :class="'collapse ' + accordion.accounting.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('valuation_class')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('valuation_category')}}</th>
				                <th class="text-white">{{tt('valuation_type')}}</th>
				                <th class="text-white">{{tt('price_determination')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('price_control')}}</th>
				                <th class="text-white">{{tt('standard_price')}}</th>
				                <th class="text-white">{{tt('price_unit')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('price_reference')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(vc, key) in prcMcrItemAccounting.valuation_class" v-bind:key="vc.code">
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.plant_accounting[key]" :disabled="prcMcrItemAccounting.status[key] == 'old' || form.disabled" style="width: 100px">
				                    <option value="">Choose Plant</option>
				                    <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
		                                {{pc}}
		                            </option>
				                  </select>
				                </td>
				                <td>
				                  <!-- <el-select v-model="prcMcrItemAccounting.valuation_class[key]" :placeholder="tt('choose_spc_proc_type')" :remote-method="querySearchValClass" :loading="selectSearch.valClassLoading" filterable remote reserve-keyword :disabled="form.disabled">
									<el-option :label="vcl.code +' - '+ vcl.description" :value="vcl.code" v-for="vcl in valuationClass" :key="'accounting' + vcl.id"></el-option>
								  </el-select> -->
								  <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.valuation_class[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Valution Class</option>
				                    <option :value="vcl.code" v-for="vcl in valuationClass" v-bind:key="vcl.code">
				                    	{{vcl.code +' - '+ vcl.description}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.valuation_category[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <option value="">Choose Valution Category</option>
				                    <option :value="vca.valuation_category_code" v-for="vca in valuationCategory" v-bind:key="vca.valuation_category_code">
				                    	{{vca.valuation_category_code +' - '+ vca.valuation_category_description}}
				                    </option>
				                  </select>
				                </td>
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.valuation_type[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3')) || prcMcrItemAccounting.valuation_category[key] == ''">
				                    <option value="">Choose Valution Type</option>
				                    <option :value="vt.valuation_type_code" v-for="vt in valuationType" v-bind:key="vt.valuation_type_code">
					                    {{vt.valuation_type_code}}
					                </option>
				                  </select>
				                </td>
				                <td>
				                    <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.price_determination[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
										<option value="">{{ tt('choose_price_determination') }}</option>
										<option value="2">2 - Transaction Based</option>
										<option value="3">3 - Single-/Multilevel</option>
									</select>
				                </td>
				                <td>
				                  <!-- <select class="form-control form-control-sm" v-model="prcMcrItemAccounting.price_control[key]" disabled>
				                    <option value="">Choose Price Control</option>
				                    <option :value="pc.price_control_code" v-for="pc in priceControl" v-bind:key="pc.price_control_code">
					                    {{pc.price_control_code +' - '+ pc.price_control_description}}
					                </option>
				                  </select> -->

				                  <input class="form-control form-control-sm" :name="tt('price_control')" :placeholder="tt('price_control')" v-model="prcMcrItemAccounting.price_control[key]" disabled>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('standard_price')" :placeholder="tt('standard_price')" v-model="prcMcrItemAccounting.standard_price[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('price_unit')" :placeholder="tt('price_unit')" v-model="prcMcrItemAccounting.price_unit[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('price_reference')" :placeholder="tt('price_reference')" v-model="prcMcrItemAccounting.price_reference[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemAccounting.status[key] == 'old'">{{ prcMcrItemAccounting.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemAccounting.status[key] == 'new'">{{ prcMcrItemAccounting.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeAccounting(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

					              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

					              	<i class="fa fa-trash text-danger" @click="removeAccounting(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemAccounting.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemAccounting.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removeAccounting(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removeAccounting(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemAccounting.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'A3' && prcMcrItemAccounting.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- PURCHASING -->

			<div id="accordionPurchasing" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.purchasing.show, 'purchasing')">
				            <b>{{ tt("purchasing") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addPurchasing" v-if="(mcrType == 'B2' || mcrType == 'B4') && !form.disabled">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addPurchasing" v-if="mcrType == 'A1' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			              <base-button size="sm" type="default" @click="addPurchasing" v-if="mcrType == 'A3' && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapsePurchasing" :class="'collapse ' + accordion.purchasing.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('purchasing_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('planned_delivery')}}</th>
				                <th class="text-white">{{tt('order_unit')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(pc, key) in prcMcrItemPurchasing.purchasing_group" v-bind:key="pc.code">
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemPurchasing.plant_purchasing[key]" :disabled="prcMcrItemPurchasing.status[key] == 'old' || form.disabled" style="width: 100px">
				                    <option value="">Choose Plant</option>
				                    <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
		                                {{pc}}
		                            </option>
				                  </select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemPurchasing.purchasing_group[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchPurGroup" :loading="selectSearch.purGroupLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemPurchasing.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
									<el-option :label="pg.code +' - '+ pg.description" :value="pg.code" v-for="pg in purchasingGroup" :key="'purchasing' + pg.id"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('planned_delivery')" :placeholder="tt('planned_delivery')" v-model="prcMcrItemPurchasing.planned_delivery[key]" rules="required" :disabled="form.disabled || (prcMcrItemPurchasing.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                </td>
				                <td>
				                	<!-- <input class="form-control form-control-sm" :name="tt('price_unit')"
				                    :placeholder="tt('order_unit')" v-model="prcMcrItemPurchasing.order_unit[key]" rules="required" :disabled="form.disabled"> -->
				                    <el-select class="select-danger" v-model="prcMcrItemPurchasing.order_unit[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchOrderUnit" :loading="selectSearch.orderUnitLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemPurchasing.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
									    <el-option class="select-danger" :value="ou.code" :label="ou.code+' - '+ou.description" :key="ou.code" v-for="ou in  orderUnit"></el-option>
									</el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemPurchasing.status[key] == 'old'">{{ prcMcrItemPurchasing.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemPurchasing.status[key] == 'new'">{{ prcMcrItemPurchasing.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removePurchasing(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

					              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

					              	<i class="fa fa-trash text-danger" @click="removePurchasing(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemPurchasing.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemPurchasing.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removePurchasing(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removePurchasing(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemPurchasing.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'A3' && prcMcrItemPurchasing.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- COSTING -->

			<div id="accordionCosting" v-if="mcrType != 'B3a' && mcrType != 'A2a'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.costing.show, 'costing')">
				            <b>{{ tt("costing") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addCosting" v-if="(mcrType == 'B2' || mcrType == 'B4') && !form.disabled">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

			              <!-- APPROVAL PAGE -->
			              <base-button size="sm" type="default" @click="addCosting" v-if="(mcrType == 'A1' || mcrType == 'A3') && formType == 'approval' && !form.disabled">{{ tt('add_new') }}</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseCosting" :class="'collapse ' + accordion.costing.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('overhead_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('costing_lot_size')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(pc, key) in prcMcrItemCosting.plant_costing" v-bind:key="pc.code">
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemCosting.plant_costing[key]" :disabled="prcMcrItemCosting.status[key] == 'old' || form.disabled" style="width: 100px">
				                    <option value="">Choose Plant</option>
				                    <option :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc">
		                                {{pc}}
		                            </option>
				                  </select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemCosting.overhead_group[key]" :placeholder="tt('overhead_group')" :disabled="form.disabled || (prcMcrItemCosting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
									<el-option :label="og.code +' - '+ og.description" :value="og.code" v-for="og in overheadGroup" :key="'costing' + og.code"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('costing_lot_size')" :placeholder="tt('costing_lot_size')" v-model="prcMcrItemCosting.costing_lot_size[key]" rules="required" :disabled="form.disabled || (prcMcrItemCosting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" value="1">
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemCosting.status[key] == 'old'">{{ prcMcrItemCosting.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemCosting.status[key] == 'new'">{{ prcMcrItemCosting.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeCosting(key)" v-if="mcrType == 'B2' && !form.disabled"></i>

					              	<span v-else-if="mcrType == 'B3a' || mcrType == 'B3b'"></span>

					              	<i class="fa fa-trash text-danger" @click="removeCosting(key)" v-else-if="mcrType == 'B4' && !form.disabled && prcMcrItemCosting.status[key] == 'new'"></i>
				                	<span v-else-if="mcrType == 'B4' && prcMcrItemCosting.status[key] == 'old'"></span>

				                	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removeCosting(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>
					              	<i class="fa fa-trash text-danger" @click="removeCosting(key)" v-else-if="formType == 'approval' && mcrType == 'A3' && !form.disabled && prcMcrItemCosting.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'A3' && prcMcrItemCosting.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- EQUIPMENT -->
	        
	        <div id="accordionEquipment" v-if="mcrType != 'B3b' && mcrType != 'A2b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.equipment.show, 'equipment')">
				            <b>{{ tt("equipment") }}</b>
				            <span class="text-danger" v-if="input.prc_item_type_code == 'OEM' || input.prc_bom_non_bom == 'BOM'"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addEquipment" v-if="!form.disabled && (mcrType == 'A1' || mcrType == 'A2a' || mcrType == 'B2' || mcrType == 'B3a')">{{ tt('add_new') }}</base-button>
			              
			              <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseEquipment" :class="'collapse ' + accordion.equipment.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('equipment_code')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('quantity_install')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('drawing_number')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('attachment')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(eqi, key) in prcMcrItemEquipment.equipment_code"
				                v-bind:key="eqi.equipment_code">
				                <td>
				                  <select class="form-control form-control-sm" v-model="prcMcrItemEquipment.plant_equipment_code[key]" :disabled="prcMcrItemEquipment.status[key] == 'old' || form.disabled">
				                    <option value="">Choose Plant</option>
				                    <option :value="pl" v-for="pl in prcMcrItemPlant.plant" v-bind:key="pl">{{pl}}</option>
				                  </select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemEquipment.equipment_code[key]" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchEquipment" :loading="selectSearch.equipmentLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
									<el-option :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code" v-for="ec in equipmentCode" :key="'equipment' + ec.id"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="prcMcrItemEquipment.qty_installed[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                </td>
				                <td>
									<el-select v-model="prcMcrItemEquipment.drawing_number[key]" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchDrawingNumber" :loading="selectSearch.drawingNumLoading" filterable remote reserve-keyword @change="changeDrawingNumber(key)" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
										<el-option :label="dm.drawing_number +' - '+ dm.drawing_name" :value="dm.drawing_number" v-for="dm in drawingMaster" :key="'equipment' + dm.id"></el-option>
									</el-select>
				                </td>
				                <td>
				                	<a :href="storageUrl+'drawing_master/'+drawingAttachment[key]" target="_blank">{{drawingAttachment[key]}}</a>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemEquipment.status[key] == 'old'">{{ prcMcrItemEquipment.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemEquipment.status[key] == 'new'">{{ prcMcrItemEquipment.status[key] }}</label>
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeEquipment(key)" v-if="mcrType == 'B2' && !form.disabled"></i>
				                	
				                	<i class="fa fa-trash text-danger" @click="removeEquipment(key)" v-else-if="mcrType == 'B3a' && !form.disabled && prcMcrItemEquipment.status[key] == 'new'"></i>
					              	<span v-else-if="mcrType == 'B3a' && prcMcrItemEquipment.status[key] == 'old'"></span>

					              	<!-- APPROVAL PAGE -->
					              	<i class="fa fa-trash text-danger" @click="removeEquipment(key)" v-else-if="formType == 'approval' && mcrType == 'A1' && !form.disabled"></i>

					              	<i class="fa fa-trash text-danger" @click="removeEquipment(key)" v-else-if="formType == 'approval' && mcrType == 'A2a' && !form.disabled && prcMcrItemEquipment.status[key] == 'new'"></i>
					              	<span v-else-if="formType == 'approval' && mcrType == 'A2a' && prcMcrItemEquipment.status[key] == 'old'"></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- ATTACHMENT DOCUMENT -->

			<div id="accordionAttDoc">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.attDoc.show, 'attDoc')">
				            <b>{{ tt("attachment_document") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
				      	<base-button size="sm" type="default" @click="smartcatAttachment" v-if="!form.disabled">SmartCat Attachment</base-button>

						<base-button size="sm" type="default" @click="addAttachment" v-if="!form.disabled">
							{{ tt('add_new') }}
						</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseAttDoc" :class="'collapse ' + accordion.attDoc.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('attachment_link')}}</th>
				                <th class="text-white" width="5">{{ tt('view') }}</th>
				                <th width="5"></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(ad, key) in prcMcrItemAttachment.file_attachment_code" v-bind:key="ad.file_name">
				                <td>
				                  <!-- <file-input 
				                    :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" 
				                    @change="changeFileAttachmentName"
				                    :initialLabel="ad.file_attachment_name" 
				                    :ref="'file'+key"></file-input> -->
				                    <input class="form-control form-control-sm" :name="tt('attachment_link')" :placeholder="tt('attachment_link')" v-model="prcMcrItemAttachment.file_attachment_code[key]" rules="required" :disabled="form.disabled">
				                </td>
				                <td>
				                	<a :href="ad" v-if="ad != ''" target="_blank">{{ tt('view') }}</a>
				                </td>
				                <td width="10"><i class="fa fa-trash text-danger" @click="removeAttachment(key)" v-if="!form.disabled"></i></td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- KEYWORD -->
	        
	        <div id="accordionKeyword" v-if="mcrType != 'B3b' && mcrType != 'A2b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.keyword.show, 'keyword')">
				            <b>{{ tt("keyword") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addKeyword" v-if="!form.disabled && (mcrType == 'A1' || mcrType == 'A2a' || mcrType == 'B2' || mcrType == 'B3a')">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseKeyword" :class="'collapse ' + accordion.keyword.show">
				    <div class="card-body border">
				    	<div class="table-responsive">
				          <table class="table align-items-center table-flush table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('keyword')}}</th>
				                <th></th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(k, key) in prcMcrItemKeyword.keyword" v-bind:key="k.keyword">
				                <td>
				                	<input class="form-control form-control-sm" :name="tt('file_name')"
				                    :placeholder="tt('keyword')" v-model="prcMcrItemKeyword.keyword[key]"
				                    rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeKeyword(key)" v-if="!form.disabled && (mcrType == 'A1' || mcrType == 'A2a' || mcrType == 'B2' || mcrType == 'B3a')"></i>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<label class="form-control-label">
	        	{{ tt('note') }} 
	        	<!-- <span class="text-danger">*</span> -->
	        </label>
	        <base-input :name="tt('note')" rules="required">
	          <textarea class="form-control" rows="5" v-model="input.note" :disabled="form.disabled"></textarea>
	        </base-input>

			<!-- PREVIEW SHORT DESCRIPTION & PO TEXT -->
	        
	        <div id="accordionPreviewDesc" v-if="formType != 'add' && mcrType != 'B3b'">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.previewDesc.show, 'previewDesc')">
				            <b>{{ tt("preview_description") }}</b>
				          </a>
				        </h5>
				      </div>
				    </div>
				  </div>
				  <div id="collapsePreviewDesc" :class="'collapse ' + accordion.previewDesc.show">
				    <div class="card-body border">
				    	<div class="border">
						<div class="p-3">
							<span style='font-size: 12px'>{{ input.short_description }}</span>
						</div>
					</div>
					<div class="border mt-3">
						<div class="p-3">
							<h5 v-if="input.prc_item_name">=== {{ input.prc_item_name }} ===</h5>
		                    <div class='row' v-for="row in prcMcrItemPoText.charValue">
		                        <span class='col-5' style='font-size: 12px'>{{row.characteristic}}</span>
		                        <span class='col-5' style='font-size: 12px'>: {{row.characteristic_value}}</span>
		                    </div>

		                    <div class="mt-4" v-if="input.prc_po_text_memo">
		                    	<h5>{{ tt('po_text_memo') }}</h5>
		                    	<pre style='font-size: 12px'>{{input.prc_po_text_memo}}</pre>
		                    </div>

		                    <h5 class="mt-4" v-if="prcMcrItemPoText.equipment.length && input.prc_item_type_code == 'OEM'">{{ tt('equipment') }}</h5>
		                    <div class='row mt-3' v-for="row in prcMcrItemPoText.equipment" v-if="input.prc_item_type_code == 'OEM'">
		                    	<span class="col-12" style='font-size: 12px'>S/P FOR {{ row.equipment_name + ' ('+ row.equipment_code +')' }}</span>
		                        <span class='col-5' style='font-size: 12px'>EQUIPMENT NAME</span>
		                        <span class='col-5' style='font-size: 12px'>: {{ row.equipment_name }}</span>

		                        <span class='col-5' style='font-size: 12px'>TYPE</span>
		                        <span class='col-5' style='font-size: 12px'>: {{ row.type }}</span>

		                        <span class='col-5' style='font-size: 12px'>SERIAL NUMBER</span>
		                        <span class='col-5' style='font-size: 12px'>: {{ row.serial_number }}</span>

		                        <span class='col-5' style='font-size: 12px'>MANUFACTURER</span>
		                        <span class='col-5' style='font-size: 12px'>: {{ row.manufacturer }}</span>

		                        <span class='col-5' style='font-size: 12px'>ADDITIONAL DATA</span>
		                        <span class='col-5' style='font-size: 12px'>: {{ row.additional_data }}</span>
		                    </div>

		                    <h5 class="mt-4" v-if="prcMcrItemMpn.manufacture_code.length">{{ tt('manufacturer_reference') }}</h5>
		                    <div class='row mt-3' v-for="(mpn, key) in prcMcrItemMpn.manufacture_code">
		                        <span class='col-5' style='font-size: 12px'>{{prcMcrItemMpn.manufacture_code[key]}}</span>
		                        <span class='col-5' style='font-size: 12px'>: {{prcMcrItemMpn.source_type[key] +':'+ prcMcrItemMpn.mpn[key]}}</span>
		                    </div>
						</div>
					</div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- MODAL SMARTCAT ATTACHMENT -->
			<modal :show.sync="modalSmartcatAttachment" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('smartcat_attachment') }}</h5>
                </template>
                <template>
				    <SmartCatAttachment/>
				</template>
            </modal>

            <!-- CHECK DUPLICATE -->
            <modal :show.sync="modalCheckDuplicate" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('check_duplicate') }}</h5>
                </template>
                <div class="row">
                	<div class="col-12">
                		<div class="card">
                            <div class="card-header pb-2">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>{{ tt('by_description') }}</h4>
                                    </div>
                                    <div class="col-6 text-right"></div>
                                </div>
                            </div>
                            <div>
                                <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="dataDuplicate.by_description">
                                    <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                                        <template v-slot="{row}">
                                            {{row.material_number}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                	</div>
                	<div class="col-12">
                        <div class="card">
                            <div class="card-header pb-2">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>{{ tt('by_part_number') }}</h4>
                                    </div>
                                    <div class="col-6 text-right"></div>
                                </div>
                            </div>
                            <div>
                                <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="dataDuplicate.by_part_number">
                                    <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                                        <template v-slot="{row}">
                                            {{row.material_number}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>

	        <div class="float-right mb-4">

	        	<base-button type="default" @click="checkDuplicate" v-if="formType == 'edit' || formType == 'detail'">Check Duplicate</base-button>

	        	<base-button
		          type="primary"
		          @click="save('add')"
		          v-if="formType == 'add'"
		          :disabled="form.btnSave.onLoading">
		          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
		          <span v-else>{{ tt("add") }}</span>
				</base-button>

		        <base-button
		          type="primary"
		          @click="save('edit')"
		          v-if="formType == 'edit'"
		          :disabled="form.btnSave.onLoading">
		          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
		          <span v-else>{{ tt("edit") }}</span>
				</base-button>

				<base-button
		          type="primary"
		          @click="save('process')"
		          v-if="formType == 'approval'"
		          :disabled="form.btnSave.onLoading">
		          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
		          <span v-else>{{ tt("process") }}</span>
				</base-button>

	        </div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import sourceType from '@/services/master/sourceType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import binloc from '@/services/master/binLocation.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeofSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';
    import purchasingGroup from '@/services/master/purchasingGroup.service';
    import checkDuplicate from '@/services/report/checkDuplicate.service';
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return { 
            	requestCalls: 0,
            	accordion: {
			        bds: {
						show: "show"
					},
					charValue: {
						show: ""
					},
					manref: {
						show: ""
					},
					plant: {
						show: ""
					},
					sloc: {
						show: ""
					},
					sales: {
						show: ""
					},
					mrp: {
						show: ""
					},
					accounting: {
						show: ""
					},
					purchasing: {
						show: ""
					},
					costing: {
						show: ""
					},
					equipment: {
						show: ""
					},
					attDoc: {
						show: ""
					},
					keyword: {
						show: ""
					},
					previewDesc: {
						show: ""
					},
		      	},
		      	toggleAccordion(show, type) {
					if (type == "bds") {
						if (show == "") {
						  this.accordion.bds.show = "show";
						} else {
						  this.accordion.bds.show = "";
						}
					} else if (type == "charValue") {
						if (show == "") {
						  this.accordion.charValue.show = "show";
						} else {
						  this.accordion.charValue.show = "";
						}
					} else if (type == "manref") {
						if (show == "") {
						  this.accordion.manref.show = "show";
						} else {
						  this.accordion.manref.show = "";
						}
					} else if (type == "plant") {
						if (show == "") {
						  this.accordion.plant.show = "show";
						} else {
						  this.accordion.plant.show = "";
						}
					} else if (type == "sloc") {
						if (show == "") {
						  this.accordion.sloc.show = "show";
						} else {
						  this.accordion.sloc.show = "";
						}
					} else if (type == "sales") {
						if (show == "") {
						  this.accordion.sales.show = "show";
						} else {
						  this.accordion.sales.show = "";
						}
					} else if (type == "mrp") {
						if (show == "") {
						  this.accordion.mrp.show = "show";
						} else {
						  this.accordion.mrp.show = "";
						}
					} else if (type == "accounting") {
						if (show == "") {
						  this.accordion.accounting.show = "show";
						} else {
						  this.accordion.accounting.show = "";
						}
					} else if (type == "purchasing") {
						if (show == "") {
						  this.accordion.purchasing.show = "show";
						} else {
						  this.accordion.purchasing.show = "";
						}
					} else if (type == "costing") {
						if (show == "") {
						  this.accordion.costing.show = "show";
						} else {
						  this.accordion.costing.show = "";
						}
					} else if (type == "equipment") {
						if (show == "") {
						  this.accordion.equipment.show = "show";
						} else {
						  this.accordion.equipment.show = "";
						}
					} else if (type == "attDoc") {
						if (show == "") {
						  this.accordion.attDoc.show = "show";
						} else {
						  this.accordion.attDoc.show = "";
						}
					} else if (type == "keyword") {
						if (show == "") {
						  this.accordion.keyword.show = "show";
						} else {
						  this.accordion.keyword.show = "";
						}
					} else if (type == "previewDesc") {
						if (show == "") {
						  this.accordion.previewDesc.show = "show";
						} else {
						  this.accordion.previewDesc.show = "";
						}
					}
			    },       
                form: {
					disabled: false,
					btnSave: {
						onLoading: false
					}
			    },
			    btnShowCharValue: {
			    	onLoading: false
			    },
			    manrefType: {},
			    sourceType: {},
                materialType: {},
                abcIndicator: {},
                mrpTypes: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: '',
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                criticalNonCritical: {},
                characteristicItemName: {},
                lotSize: {},
                procurementType: {},
                charValue: {},
                drawingAttachment: [],
                materialNumber: {},

                // SELECT SEARCH
                selectSearch: {
                	sitLoading: false,
                	ngcLoading: false,
                	uomLoading: false,
                	mtLoading: false,
                	ctLoading: false,
                	mgLoading: false,
                	sosLoading: false,
                	mancodeLoading: false,
                	plantLoading: false,
                	slocLoading: false,
                	binlocLoading: false,
                	pcenterLoading: false,
                	mrpControllerLoading: false,
                	spcProcTypeLoading: false,
                	valClassLoading: false,
                	purGroupLoading: false,
                	equipmentLoading: false,
                	drawingNumLoading: false,
                	matnumLoading: false,
                	orderUnitLoading: false,
                    loadTimeout: null
                },
                standardItemName: {},
                natoGroupClass: {},
                unitOfMeasure: {},
                orderUnit: {},
                catalogType: {},
                materialGroup: {},
                scopeOfSupply: {},
                masterManufacture: {},
                plantCode: {},
                locationCode: {},
                binloc: {},
                profitCenter: {},
                mrpController: {},
                specialProcurementType: {},
                valuationClass: {},
                purchasingGroup:{},
                overheadGroup: {},
                equipmentCode: {},
                drawingMaster: {},

                input: {
                	id: '',
                	prc_inc: '',
                    prc_item_type_code: '',
                    prc_nsc: '',
                    prc_uom_code: '',
                    prc_mtype_code: '',
                    prc_catalog_type_code: '',
                    prc_mgroup_code: '',
                    prc_fab_non_fab: '',
                    prc_sos_header: '',
                    prc_bom_non_bom: '',
                    prc_critical_non_critical: '',
                    prc_po_text_memo: '',
                    note: '',
                    material_number: '',
                    prc_item_name: '',
                    short_description: '',
                    status_process: ''
                },
                prcMcrItemCharValue: {
                  cin_code: [],
                  characteristic: [],
                  value: [],
                  view: [],
                  multipleChar: [],
                  code: [],
                },
                prcMcrItemPoText: {
                	charValue: {},
                	equipment: {},
                },
                prcMcrItemMpn: {
                    manufacture_code: [],
                    mpn: [],
                    source_type: [],
                    manufacture_type: [],
                    manufacture_note: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemPlant: {
                    plant: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemSloc: {
                    plant_code: [],
                    storage_location: [],
                    array_storage_location: [],
                    warehouse_number: [],
                    storage_type: [],
                    bin_location: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemSales: {
                    sls_plant_code:[],
                    sales_org: [],
                    distr_channel: [],
                    sales_unit: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemMrp: {
                    plant_mrp:[],
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                    max_stock: [],
                    fixed_lot: [],
                    ord_costs: [],
                    reorder_point: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemAccounting: {
                    plant_accounting:[],
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    standard_price:[],
                    price_unit: [],
                    price_reference: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemPurchasing: {
                    plant_purchasing: [],
                    purchasing_group: [],
                    order_unit: [],
                    planned_delivery: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemCosting: {
                    plant_costing: [],
                    overhead_group: [],
                    costing_lot_size: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemEquipment: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemAttachment: {
                    file_attachment_code: [],
                },
                prcMcrItemKeyword: {
                    keyword: []
                },
                dataDuplicate: {
                	by_description: [],
                	by_part_number: [],
                },
                previewPoTextModal: false,
                modalSmartcatAttachment: false,
                modalCheckDuplicate: false,

                storageUrl: baseApiUrl.storageUrl,
                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type, // create, approval,dll
			    mcrType: this.$route.params.mcr_type // B2,B3,dll
            }
        },
        watch: {
          'input.material_number': function() {
          	if ((this.mcrType == 'B3a' || this.mcrType == 'B3b' || this.mcrType == 'B4') && this.formType == 'add') {
          		this.changeMaterialNumber();
          	} else if ((this.mcrType == 'A2a' || this.mcrType == 'A2b' || this.mcrType == 'A3') && (this.formType == 'approval' || this.formType == 'approval-detail')) {
          		if (this.input.status_process == 'Unprocessed') {
          			this.changeMaterialNumber();
          		}
          	}
          },
          'input.prc_mtype_code': function() {
          	this.getValuationClassByAccCatRef();
          	this.getValuationTypeByAccCatRef();
          	this.changePriceControl();
          },
          'prcMcrItemPlant.plant': function() {
          	this.dependOnPlant()
          }
        },
        mounted() {
        	this.checkFormType();
            this.getParameterMcr();

            if (this.formType == 'edit' || this.formType == 'detail' || this.formType == 'approval' || this.formType == 'approval-detail' || this.mcrType == 'B3a' || this.mcrType == 'B3b' || this.mcrType == 'B4') {
	            // mrp
	            this.getAbcIndicator();
	            this.getMrpTypes();
	            this.getLotSize();
	            this.getProcurementType();
	            // accounting
	            this.getValuationCategory();
	            // this.getValuationType();
	            // this.getPriceControl();
	            // sales
	            this.getSalesOrganization();
	            this.getDistributionChannel();
	            this.getDivision();
	            this.getTaxClassification();
	            this.getItemCategoryGroup();
	            this.getAccountAssignmentGroup();
	            this.getTransportationGroup();
	            this.getLoadingGroup();
	            // mpn
	            this.getManrefType();
	            this.getSourceType();
	            this.getOverheadGroup();
			}

			if (this.formType == 'edit' || this.formType == 'detail' || this.formType == 'approval') {
				this.checkDuplicateByMcrItemCode();
			}

			// if (this.formType == 'edit' || this.formType == 'detail') {
			// 	// this.getMaterialNumber();
			// 	this.getDetailMcrItem();
			// } else if ((this.formType == 'approval' || this.formType == 'approval-detail') && this.mcrType == 'A1') {
			// 	// this.getMaterialNumber();
			// 	this.getDetailMcrItem();
			// } else if ((this.formType == 'approval' || this.formType == 'approval-detail') && this.mcrType == 'A2') {
			// 	// this.getMaterialNumber();
			// 	this.getDetailMcrItem();
			// } else if ((this.formType == 'approval' || this.formType == 'approval-detail') && this.mcrType == 'A3') {
			// 	// this.getMaterialNumber();
			// 	this.getDetailMcrItem();
			// }

			if (this.formType != 'add') {
				this.getDetailMcrItem();
			}
        },
        methods: {
        	// CHECK FORM TYPE

			checkFormType() {
				switch(this.formType) {
					case 'edit':
						this.form.disabled = false;
						break;
					case 'detail':
						this.form.disabled = true;
						break;
					case 'approval-detail':
						this.form.disabled = true;
						break;
				}
			},

			// GET MATERIAL NUMBER

			// getMaterialNumber() {
			// 	this.requestCalls++;

			// 	let context = this;

			// 	Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function (response) {
			// 		context.input.id = response.data.data[0].id
   //                  context.input.material_number = (response.data.data[0].material_number == null) ? '' : response.data.data[0].material_number
   //              }).onError(function (error) {
	  //               if (error.response.status == 404) {
	  //               	context.input.id = '';
	  //                   context.input.material_number = '';
	  //               }
   //              }).onFinish(function() {
   //              	context.requestCalls--;
   //              }).call()
			// },

			// GET DETAIL FROM MCR ITEM CODE

            getDetailMcrItem() {
            	this.requestCalls++;

            	this.accordion.charValue.show = "show";
            	this.accordion.manref.show = "show";
            	this.accordion.plant.show = "show";
            	this.accordion.sloc.show = "show";
            	this.accordion.sales.show = "show";
            	this.accordion.mrp.show = "show";
            	this.accordion.accounting.show = "show";
            	this.accordion.purchasing.show = "show";
            	this.accordion.costing.show = "show";
            	this.accordion.equipment.show = "show";
            	this.accordion.attDoc.show = "show";
            	this.accordion.keyword.show = "show";
            	this.accordion.previewDesc.show = "show";

                let context = this;

                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function (response) {
                  	// context.input = response.data.data[0];

                  	context.input.id = response.data.data[0].id
                  	context.input.material_number = response.data.data[0].material_number
                	context.input.prc_inc = (response.data.data[0].prc_inc == null) ? '' : response.data.data[0].prc_inc
                    context.input.prc_item_type_code = (response.data.data[0].prc_item_type_code == null) ? '' : response.data.data[0].prc_item_type_code
                    context.input.prc_nsc = (response.data.data[0].prc_nsc == null) ? '' : response.data.data[0].prc_nsc
                    context.input.prc_uom_code = (response.data.data[0].prc_uom_code == null) ? '' : response.data.data[0].prc_uom_code
                    context.input.prc_mtype_code = (response.data.data[0].prc_mtype_code == null) ? '' : response.data.data[0].prc_mtype_code
                    context.input.prc_catalog_type_code = (response.data.data[0].prc_catalog_type_code == null) ? '' : response.data.data[0].prc_catalog_type_code
                    context.input.prc_mgroup_code = (response.data.data[0].prc_mgroup_code == null) ? '' : response.data.data[0].prc_mgroup_code
                    context.input.prc_fab_non_fab = (response.data.data[0].prc_fab_non_fab == null) ? '' : response.data.data[0].prc_fab_non_fab
                    context.input.prc_sos_header = (response.data.data[0].prc_sos_header == null) ? '' : response.data.data[0].prc_sos_header
                    context.input.prc_bom_non_bom = (response.data.data[0].prc_bom_non_bom == null) ? '' : response.data.data[0].prc_bom_non_bom
                    context.input.prc_critical_non_critical = (response.data.data[0].prc_critical_non_critical == null) ? '' : response.data.data[0].prc_critical_non_critical
                    context.input.prc_po_text_memo = (response.data.data[0].prc_po_text_memo == null) ? '' : response.data.data[0].prc_po_text_memo
                    context.input.note = (response.data.data[0].internal_note == null) ? '' : response.data.data[0].internal_note
                    context.input.prc_item_name = (response.data.data[0].prc_item_name == null) ? '' : response.data.data[0].prc_item_name
                    context.input.short_description = (response.data.data[0].prc_short_description == null) ? '' : response.data.data[0].prc_short_description
                    context.input.status_process = response.data.data[0].status_process
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.input = [];
	                }
                }).call()

                // PRC MCR ITEM CHARACTERISTIC VALUE
                Api(context, draftList.getPrcMcrItemCharacteristicValue(this.mcrItemCode)).onSuccess(function (response) {
					context.prcMcrItemCharValue.cin_code = [];
					context.prcMcrItemCharValue.characteristic = [];
					context.prcMcrItemCharValue.value = [];
					context.prcMcrItemCharValue.view = [];
					context.prcMcrItemCharValue.multipleChar = [];
					context.prcMcrItemCharValue.code = [];

					for (var i = 0; i < response.data.data.length; i++) {
					    context.prcMcrItemCharValue.cin_code[i] = (response.data.data[i].cin_code == null) ? '' : response.data.data[i].cin_code
					    context.prcMcrItemCharValue.characteristic[i] = (response.data.data[i].characteristic == null) ? '' : response.data.data[i].characteristic
					    context.prcMcrItemCharValue.value[i] = (response.data.data[i].characteristic_value == null) ? '' : response.data.data[i].characteristic_value
					    context.prcMcrItemCharValue.view[i] = (response.data.data[i].view == 'checked' ? true : false);;
					    context.prcMcrItemCharValue.multipleChar[i] = (response.data.data[i].single_or_multiple == null) ? '' : response.data.data[i].single_or_multiple
					    context.prcMcrItemCharValue.code[i] = (response.data.data[i].code == null) ? '' : response.data.data[i].code
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemCharValue.cin_code = [];
	                    context.prcMcrItemCharValue.characteristic = [];
	                    context.prcMcrItemCharValue.value = [];
	                    context.prcMcrItemCharValue.view = [];
	                    context.prcMcrItemCharValue.multipleChar = [];
	                    context.prcMcrItemCharValue.code = [];
	                }
                }).call()

                // DATA FOR PO TEXT
                Api(context, draftList.getPrcMcrItemPoText(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPoText.charValue = (response.data.data.dataCharValue == null) ? '' : response.data.data.dataCharValue
                	context.prcMcrItemPoText.equipment = (response.data.data.dataEquipment == null) ? '' : response.data.data.dataEquipment
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPoText.charValue = {};
	                    context.prcMcrItemPoText.equipment = {};
	                }
                }).call()

                // PRC MCR ITEM MPN
                Api(context, draftList.getPrcMcrItemMpn(this.mcrItemCode)).onSuccess(function (response) {
                  	context.prcMcrItemMpn.manufacture_code = []
					context.prcMcrItemMpn.mpn = []
					context.prcMcrItemMpn.source_type = []
					context.prcMcrItemMpn.manufacture_type = []
					context.prcMcrItemMpn.manufacture_note = []
					context.prcMcrItemMpn.status = []

					for (var i = 0; i < response.data.data.length; i++) {
					  context.prcMcrItemMpn.manufacture_code[i] = (response.data.data[i].manufacture_code == null) ? '' : response.data.data[i].manufacture_code
					  context.prcMcrItemMpn.mpn[i] = (response.data.data[i].manufacture_ref == null) ? '' : response.data.data[i].manufacture_ref
					  context.prcMcrItemMpn.source_type[i] = (response.data.data[i].source_type_code == null) ? '' : response.data.data[i].source_type_code
					  context.prcMcrItemMpn.manufacture_type[i] = (response.data.data[i].manref_type_code == null) ? '' : response.data.data[i].manref_type_code
					  context.prcMcrItemMpn.manufacture_note[i] = (response.data.data[i].note == null) ? '' : response.data.data[i].note
					  context.prcMcrItemMpn.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemMpn.manufacture_code = [];
	                    context.prcMcrItemMpn.mpn = [];
	                    context.prcMcrItemMpn.source_type = [];
	                    context.prcMcrItemMpn.manufacture_type = [];
	                    context.prcMcrItemMpn.manufacture_note = [];
	                    context.prcMcrItemMpn.status = [];
	                }
                }).call()

                // PRC MCR ITEM PLANT
                Api(context, draftList.getPrcMcrItemPlant(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPlant.plant = [];
                	context.prcMcrItemPlant.status = [];
                	context.prcMcrItemPlant.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemPlant.plant[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemPlant.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemPlant.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPlant.plant = [];
	                    context.prcMcrItemPlant.status = [];
	                    context.prcMcrItemPlant.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM STORAGE LOCATION
                Api(context, draftList.getPrcMcrItemStorageLocation(this.mcrItemCode)).onSuccess(function (response) {
					context.prcMcrItemSloc.plant_code = [];
					context.prcMcrItemSloc.storage_location = [];
					context.prcMcrItemSloc.warehouse_number = [];
					context.prcMcrItemSloc.storage_type = [];
					context.prcMcrItemSloc.bin_location = [];
					context.prcMcrItemSloc.status = [];
					context.prcMcrItemSloc.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemSloc.plant_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemSloc.storage_location[i] = (response.data.data[i].location_code == null) ? '' : response.data.data[i].location_code
						// context.prcMcrItemSloc.warehouse_number[i] = response.data.data[i].warehouse_no;
						// context.prcMcrItemSloc.storage_type[i] = response.data.data[i].storage_type_code;
						context.prcMcrItemSloc.bin_location[i] = (response.data.data[i].bin_code == null) ? '' : response.data.data[i].bin_code
						context.prcMcrItemSloc.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemSloc.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemSloc.plant_code = [];
						context.prcMcrItemSloc.storage_location = [];
						context.prcMcrItemSloc.warehouse_number = [];
						context.prcMcrItemSloc.storage_type = [];
						context.prcMcrItemSloc.bin_location = [];
						context.prcMcrItemSloc.status = [];
						context.prcMcrItemSloc.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM SALES
                Api(context, draftList.getPrcMcrItemSales(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemSales.sls_plant_code = [];
                    context.prcMcrItemSales.sales_org = [];
                    context.prcMcrItemSales.distr_channel = [];
                    context.prcMcrItemSales.sales_unit = [];
                    context.prcMcrItemSales.division = [];
                    // context.prcMcrItemSales.tax_cat = [];
                    context.prcMcrItemSales.tax_class = [];
                    context.prcMcrItemSales.gen_item_cat_grp = [];
                    context.prcMcrItemSales.account_assign_grp = [];
                    context.prcMcrItemSales.item_cat_grp = [];
                    context.prcMcrItemSales.trans_group = [];
                    context.prcMcrItemSales.loading_group = [];
                    context.prcMcrItemSales.profit_center = [];
                    context.prcMcrItemSales.status = [];
                    context.prcMcrItemSales.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemSales.sls_plant_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemSales.sales_org[i] = (response.data.data[i].sales_org_code == null) ? '' : response.data.data[i].sales_org_code
						context.prcMcrItemSales.distr_channel[i] = (response.data.data[i].distr_channel_code == null) ? '' : response.data.data[i].distr_channel_code
						context.prcMcrItemSales.sales_unit[i] = (response.data.data[i].sales_unit == null) ? '' : response.data.data[i].sales_unit
						context.prcMcrItemSales.division[i] = (response.data.data[i].division_code == null) ? '' : response.data.data[i].division_code
						// context.prcMcrItemSales.tax_cat[i] = response.data.data[i].tax_cat_code;
						context.prcMcrItemSales.tax_class[i] = (response.data.data[i].tax_class_code == null) ? '' : response.data.data[i].tax_class_code
						context.prcMcrItemSales.gen_item_cat_grp[i] = (response.data.data[i].gen_item_cat_grp_code == null) ? '' : response.data.data[i].gen_item_cat_grp_code
						context.prcMcrItemSales.account_assign_grp[i] = (response.data.data[i].acc_ass_grp_code == null) ? '' : response.data.data[i].acc_ass_grp_code
						context.prcMcrItemSales.item_cat_grp[i] = (response.data.data[i].item_cat_grp_code == null) ? '' : response.data.data[i].item_cat_grp_code
						context.prcMcrItemSales.trans_group[i] = (response.data.data[i].trans_group_code == null) ? '' : response.data.data[i].trans_group_code
						context.prcMcrItemSales.loading_group[i] = (response.data.data[i].loading_group_code == null) ? '' : response.data.data[i].loading_group_code
						context.prcMcrItemSales.profit_center[i] = (response.data.data[i].profit_center_code == null) ? '' : response.data.data[i].profit_center_code
						context.prcMcrItemSales.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemSales.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemSales.sls_plant_code = [];
	                    context.prcMcrItemSales.sales_org = [];
	                    context.prcMcrItemSales.distr_channel = [];
	                    context.prcMcrItemSales.sales_unit = [];
	                    context.prcMcrItemSales.division = [];
	                    // context.prcMcrItemSales.tax_cat = [];
	                    context.prcMcrItemSales.tax_class = [];
	                    context.prcMcrItemSales.gen_item_cat_grp = [];
	                    context.prcMcrItemSales.account_assign_grp = [];
	                    context.prcMcrItemSales.item_cat_grp = [];
	                    context.prcMcrItemSales.trans_group = [];
	                    context.prcMcrItemSales.loading_group = [];
	                    context.prcMcrItemSales.profit_center = [];
	                    context.prcMcrItemSales.status = [];
	                    context.prcMcrItemSales.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM MRP
                Api(context, draftList.getPrcMcrItemMrp(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemMrp.plant_mrp = [];
                    context.prcMcrItemMrp.abc_indicator = [];
                    context.prcMcrItemMrp.mrp_type = [];
                    context.prcMcrItemMrp.mrp_controller = [];
                    context.prcMcrItemMrp.lot_size = [];
                    context.prcMcrItemMrp.min = [];
                    context.prcMcrItemMrp.max = [];
                    context.prcMcrItemMrp.procurement_type = [];
                    context.prcMcrItemMrp.special_proc = [];
                    context.prcMcrItemMrp.max_stock = [];
                    context.prcMcrItemMrp.fixed_lot = [];
                    context.prcMcrItemMrp.ord_costs = [];
                    context.prcMcrItemMrp.reorder_point = [];
                    context.prcMcrItemMrp.status = [];
                    context.prcMcrItemMrp.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemMrp.plant_mrp[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemMrp.abc_indicator[i] = (response.data.data[i].abc_indicator_code == null) ? '' : response.data.data[i].abc_indicator_code
						context.prcMcrItemMrp.mrp_type[i] = (response.data.data[i].mrp_type_code == null) ? '' : response.data.data[i].mrp_type_code
						context.prcMcrItemMrp.mrp_controller[i] = (response.data.data[i].mrp_controller_code == null) ? '' : response.data.data[i].mrp_controller_code
						context.prcMcrItemMrp.lot_size[i] = (response.data.data[i].lot_size_code == null) ? '' : response.data.data[i].lot_size_code
						context.prcMcrItemMrp.min[i] = (response.data.data[i].min == null) ? '' : response.data.data[i].min
						context.prcMcrItemMrp.max[i] = (response.data.data[i].max == null) ? '' : response.data.data[i].max
						context.prcMcrItemMrp.procurement_type[i] = (response.data.data[i].procurement_type_code == null) ? '' : response.data.data[i].procurement_type_code
						context.prcMcrItemMrp.special_proc[i] = (response.data.data[i].special_proc_type_code == null) ? '' : response.data.data[i].special_proc_type_code
						context.prcMcrItemMrp.max_stock[i] = (response.data.data[i].max_stock == null) ? '' : response.data.data[i].max_stock
						context.prcMcrItemMrp.fixed_lot[i] = (response.data.data[i].fixed_lot == null) ? '' : response.data.data[i].fixed_lot
						context.prcMcrItemMrp.ord_costs[i] = (response.data.data[i].ord_costs == null) ? '' : response.data.data[i].ord_costs
						context.prcMcrItemMrp.reorder_point[i] = (response.data.data[i].reorder_point == null) ? '' : response.data.data[i].reorder_point
						context.prcMcrItemMrp.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemMrp.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemMrp.plant_mrp = [];
	                    context.prcMcrItemMrp.abc_indicator = [];
	                    context.prcMcrItemMrp.mrp_type = [];
	                    context.prcMcrItemMrp.mrp_controller = [];
	                    context.prcMcrItemMrp.lot_size = [];
	                    context.prcMcrItemMrp.min = [];
	                    context.prcMcrItemMrp.max = [];
	                    context.prcMcrItemMrp.procurement_type = [];
	                    context.prcMcrItemMrp.special_proc = [];
	                    context.prcMcrItemMrp.max_stock = [];
	                    context.prcMcrItemMrp.fixed_lot = [];
	                    context.prcMcrItemMrp.ord_costs = [];
	                    context.prcMcrItemMrp.reorder_point = [];
	                    context.prcMcrItemMrp.status = [];
	                    context.prcMcrItemMrp.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM ACCOUNTING
                Api(context, draftList.getPrcMcrItemAccounting(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemAccounting.plant_accounting = [];
                    context.prcMcrItemAccounting.valuation_class = [];
                    context.prcMcrItemAccounting.valuation_category = [];
                    context.prcMcrItemAccounting.valuation_type = [];
                    context.prcMcrItemAccounting.price_control = [];
                    context.prcMcrItemAccounting.price_determination = [];
                    context.prcMcrItemAccounting.standard_price = [];
                    context.prcMcrItemAccounting.price_unit = [];
                    context.prcMcrItemAccounting.price_reference = [];
                    context.prcMcrItemAccounting.status = [];
                    context.prcMcrItemAccounting.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemAccounting.plant_accounting[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemAccounting.valuation_class[i] = (response.data.data[i].valuation_class_code == null) ? '' : response.data.data[i].valuation_class_code
						context.prcMcrItemAccounting.valuation_category[i] = (response.data.data[i].valuation_category_code == null) ? '' : response.data.data[i].valuation_category_code
						context.prcMcrItemAccounting.valuation_type[i] = (response.data.data[i].valuation_type_code == null) ? '' : response.data.data[i].valuation_type_code
						context.prcMcrItemAccounting.price_control[i] = (response.data.data[i].price_control_code == null) ? '' : response.data.data[i].price_control_code
						context.prcMcrItemAccounting.price_determination[i] = (response.data.data[i].price_determination == null) ? '' : response.data.data[i].price_determination
						context.prcMcrItemAccounting.standard_price[i] = (response.data.data[i].standard_price == null) ? '' : response.data.data[i].standard_price
						context.prcMcrItemAccounting.price_unit[i] = (response.data.data[i].price_unit == null) ? '' : response.data.data[i].price_unit
						context.prcMcrItemAccounting.price_reference[i] = (response.data.data[i].price_reference == null) ? '' : response.data.data[i].price_reference
						context.prcMcrItemAccounting.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemAccounting.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemAccounting.plant_accounting = [];
	                    context.prcMcrItemAccounting.valuation_class = [];
	                    context.prcMcrItemAccounting.valuation_category = [];
	                    context.prcMcrItemAccounting.valuation_type = [];
	                    context.prcMcrItemAccounting.price_control = [];
	                    context.prcMcrItemAccounting.price_determination = [];
	                    context.prcMcrItemAccounting.standard_price = [];
	                    context.prcMcrItemAccounting.price_unit = [];
	                    context.prcMcrItemAccounting.price_reference = [];
	                    context.prcMcrItemAccounting.status = [];
	                    context.prcMcrItemAccounting.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM PURCHASING
                Api(context, draftList.getPrcMcrItemPurchasing(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPurchasing.plant_purchasing = [];
                    context.prcMcrItemPurchasing.purchasing_group = [];
                    context.prcMcrItemPurchasing.order_unit = [];
                    context.prcMcrItemPurchasing.planned_delivery = [];
                    context.prcMcrItemPurchasing.status = [];
                    context.prcMcrItemPurchasing.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemPurchasing.plant_purchasing[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemPurchasing.purchasing_group[i] = (response.data.data[i].purchasing_group_code == null) ? '' : response.data.data[i].purchasing_group_code
						context.prcMcrItemPurchasing.order_unit[i] = (response.data.data[i].order_unit == null) ? '' : response.data.data[i].order_unit
						context.prcMcrItemPurchasing.planned_delivery[i] = (response.data.data[i].planned_delivery == null) ? '' : response.data.data[i].planned_delivery
						context.prcMcrItemPurchasing.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemPurchasing.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPurchasing.plant_purchasing = [];
	                    context.prcMcrItemPurchasing.purchasing_group = [];
	                    context.prcMcrItemPurchasing.order_unit = [];
	                    context.prcMcrItemPurchasing.planned_delivery = [];
	                    context.prcMcrItemPurchasing.status = [];
	                    context.prcMcrItemPurchasing.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM COSTING
                Api(context, draftList.getPrcMcrItemCosting(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemCosting.plant_costing = [];
                    context.prcMcrItemCosting.overhead_group = [];
                    context.prcMcrItemCosting.costing_lot_size = [];
                    context.prcMcrItemCosting.status = [];
                    context.prcMcrItemCosting.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemCosting.plant_costing[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemCosting.overhead_group[i] = (response.data.data[i].overhead_group == null) ? '' : response.data.data[i].overhead_group
						context.prcMcrItemCosting.costing_lot_size[i] = (response.data.data[i].costing_lot_size == null) ? '' : response.data.data[i].costing_lot_size
						context.prcMcrItemCosting.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemCosting.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemCosting.plant_costing = [];
	                    context.prcMcrItemCosting.overhead_group = [];
	                    context.prcMcrItemCosting.costing_lot_size = [];
	                    context.prcMcrItemCosting.status = [];
	                    context.prcMcrItemCosting.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM EQUIPMENT
                Api(context, draftList.getPrcMcrItemEquipment(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemEquipment.plant_equipment_code = [];
                    context.prcMcrItemEquipment.equipment_code = [];
                    context.prcMcrItemEquipment.qty_installed = [];
                    context.prcMcrItemEquipment.drawing_number = [];
                    context.prcMcrItemEquipment.del_flag = [];
                    context.prcMcrItemEquipment.status = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemEquipment.plant_equipment_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemEquipment.equipment_code[i] = (response.data.data[i].equipment_code == null) ? '' : response.data.data[i].equipment_code
						context.prcMcrItemEquipment.qty_installed[i] = (response.data.data[i].qty_installed == null) ? '' : response.data.data[i].qty_installed
						context.prcMcrItemEquipment.drawing_number[i] = (response.data.data[i].drawing_number == null) ? '' : response.data.data[i].drawing_number
						context.prcMcrItemEquipment.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
						context.prcMcrItemEquipment.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.drawingAttachment[i] = (response.data.data[i].file_name == null) ? '' : response.data.data[i].file_name
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemEquipment.plant_equipment_code = [];
	                    context.prcMcrItemEquipment.equipment_code = [];
	                    context.prcMcrItemEquipment.qty_installed = [];
	                    context.prcMcrItemEquipment.drawing_number = [];
	                    context.prcMcrItemEquipment.del_flag = [];
	                    context.prcMcrItemEquipment.status = [];
	                }
                }).call()

                // PRC MCR ITEM ATTACHMENT
                Api(context, draftList.getPrcMcrItemAttachment(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemAttachment.file_attachment_code = [];

                  	for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemAttachment.file_attachment_code[i] = (response.data.data[i].file_attachment_code == null) ? '' : response.data.data[i].file_attachment_code
					}
                }).onError(function (error) {
					if (error.response.status == 404) {
						context.prcMcrItemAttachment.file_attachment_code = [];
					}
                }).call()

                // PRC MCR ITEM KEYWORD
                Api(context, draftList.getPrcMcrItemKeyword(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemKeyword.keyword = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemKeyword.keyword[i] = (response.data.data[i].keyword == null) ? '' : response.data.data[i].keyword
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemKeyword.keyword = [];
	                }
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
            },

            changeMaterialNumber() {
            	this.requestCalls++;

            	this.accordion.charValue.show = "show";
            	this.accordion.manref.show = "show";
            	this.accordion.plant.show = "show";
            	this.accordion.sloc.show = "show";
            	this.accordion.sales.show = "show";
            	this.accordion.mrp.show = "show";
            	this.accordion.accounting.show = "show";
            	this.accordion.purchasing.show = "show";
            	this.accordion.costing.show = "show";
            	this.accordion.equipment.show = "show";
            	this.accordion.attDoc.show = "show";
            	this.accordion.keyword.show = "show";

            	let context = this;

            	Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.input.material_number})).onSuccess(function(response) {
            		// GET BASIC DATA MATERIAL 

            		// context.input.id = response.data.data.part_master[0].id
                	context.input.prc_inc = (response.data.data.part_master[0].inc == null) ? '' : response.data.data.part_master[0].inc
                	context.input.prc_item_name = (response.data.data.part_master[0].item_name == null) ? '' : response.data.data.part_master[0].item_name
                    context.input.prc_item_type_code = (response.data.data.part_master[0].item_type_code == null) ? '' : response.data.data.part_master[0].item_type_code
                    context.input.prc_nsc = (response.data.data.part_master[0].nsc == null) ? '' : response.data.data.part_master[0].nsc
                    context.input.prc_uom_code = (response.data.data.part_master[0].uom == null) ? '' : response.data.data.part_master[0].uom
                    context.input.prc_mtype_code = (response.data.data.part_master[0].material_type == null) ? '' : response.data.data.part_master[0].material_type
                    context.input.prc_catalog_type_code = (response.data.data.part_master[0].cat_type == null) ? '' : response.data.data.part_master[0].cat_type
                    context.input.prc_mgroup_code = (response.data.data.part_master[0].material_group == null) ? '' : response.data.data.part_master[0].material_group
                    context.input.prc_fab_non_fab = (response.data.data.part_master[0].fab_non_fab == null) ? '' : response.data.data.part_master[0].fab_non_fab
                    context.input.prc_sos_header = (response.data.data.part_master[0].scope_of_supply_code == null) ? '' : response.data.data.part_master[0].scope_of_supply_code
                    context.input.prc_bom_non_bom = (response.data.data.part_master[0].bom_non_bom == null) ? '' : response.data.data.part_master[0].bom_non_bom
                    context.input.prc_critical_non_critical = (response.data.data.part_master[0].critical_non_critical == null) ? '' : response.data.data.part_master[0].critical_non_critical
                    context.input.prc_po_text_memo = (response.data.data.part_master[0].po_text_memo == null) ? '' : response.data.data.part_master[0].po_text_memo
                    context.input.note = (response.data.data.part_master[0].note == null) ? '' : response.data.data.part_master[0].note
                    context.input.short_description = (response.data.data.part_master[0].short_description == null) ? '' : response.data.data.part_master[0].short_description

                    // select option item name
        			context.standardItemName = [
        				{
	        				inc: response.data.data.part_master[0].inc,
	        				item_name: response.data.data.part_master[0].item_name,
	        			}
        			]

        			// GET CHARACTERISTIC VALUE

        			context.prcMcrItemCharValue.cin_code = [];
					context.prcMcrItemCharValue.characteristic = [];
					context.prcMcrItemCharValue.value = [];
					context.prcMcrItemCharValue.view = [];
					context.prcMcrItemCharValue.multipleChar = [];
					context.prcMcrItemCharValue.code = [];

					for (var i = 0; i < response.data.data.data_part_char_value.length; i++) {
					    context.prcMcrItemCharValue.cin_code[i] = (response.data.data.data_part_char_value[i].cin_code == null) ? '' : response.data.data.data_part_char_value[i].cin_code
					    context.prcMcrItemCharValue.characteristic[i] = (response.data.data.data_part_char_value[i].characteristic == null) ? '' : response.data.data.data_part_char_value[i].characteristic
					    context.prcMcrItemCharValue.value[i] = (response.data.data.data_part_char_value[i].characteristic_value == null) ? '' : response.data.data.data_part_char_value[i].characteristic_value
					    context.prcMcrItemCharValue.view[i] = (response.data.data.data_part_char_value[i].view == 'checked' ? true : false)
					    context.prcMcrItemCharValue.multipleChar[i] = (response.data.data.data_part_char_value[i].single_or_multiple == null) ? '' : response.data.data.data_part_char_value[i].single_or_multiple
					    context.prcMcrItemCharValue.code[i] = (response.data.data.data_part_char_value[i].code == null) ? '' : response.data.data.data_part_char_value[i].code

					    // for po text
					    context.prcMcrItemPoText.charValue = (response.data.data.po_text == null) ? '' : response.data.data.po_text
					}

					// GET MANUFACTURE

					context.prcMcrItemMpn.manufacture_code = []
					context.prcMcrItemMpn.source_type_code = []
					context.prcMcrItemMpn.mpn = []
					context.prcMcrItemMpn.manufacture_type = []
					context.prcMcrItemMpn.manufacture_note = []
					context.prcMcrItemMpn.status = []

					for (var i = 0; i < response.data.data.part_manufacture_ref.length; i++) {
	                      context.prcMcrItemMpn.manufacture_code[i] = (response.data.data.part_manufacture_ref[i].manufacture_code == null) ? '' : response.data.data.part_manufacture_ref[i].manufacture_code
	                      context.prcMcrItemMpn.source_type[i] = (response.data.data.part_manufacture_ref[i].source_type_code == null) ? '' : response.data.data.part_manufacture_ref[i].source_type_code
	                      context.prcMcrItemMpn.mpn[i] = (response.data.data.part_manufacture_ref[i].manufacture_ref == null) ? '' : response.data.data.part_manufacture_ref[i].manufacture_ref
	                      context.prcMcrItemMpn.manufacture_type[i] = (response.data.data.part_manufacture_ref[i].type == null) ? '' : response.data.data.part_manufacture_ref[i].type
	                      context.prcMcrItemMpn.manufacture_note[i] = (response.data.data.part_manufacture_ref[i].note == null) ? '' : response.data.data.part_manufacture_ref[i].note
	                      context.prcMcrItemMpn.status[i] = 'old';
	                }

	                // GET PLANT

	                context.prcMcrItemPlant.status = [];
	                context.prcMcrItemPlant.plant = [];
	                context.prcMcrItemPlant.del_flag = [];

	                for (var i = 0; i < response.data.data.part_plant.length; i++) {
                      	context.prcMcrItemPlant.plant[i] = (response.data.data.part_plant[i].plant_code == null) ? '' : response.data.data.part_plant[i].plant_code
                      	context.prcMcrItemPlant.del_flag[i] = (response.data.data.part_plant[i].del_flag == null) ? '' : response.data.data.part_plant[i].del_flag
                      	context.prcMcrItemPlant.status[i] = 'old';
                  	}

                  	// GET STORAGE LOCATION

                  	context.prcMcrItemSloc.status = [];
                  	context.prcMcrItemSloc.plant_code = [];
					context.prcMcrItemSloc.storage_location = [];
					context.prcMcrItemSloc.warehouse_number = [];
					context.prcMcrItemSloc.storage_type = [];
					context.prcMcrItemSloc.bin_location = [];
					context.prcMcrItemSloc.status = [];
					context.prcMcrItemSloc.del_flag = [];

					for (var i = 0; i < response.data.data.part_storage_location.length; i++) {
					  context.prcMcrItemSloc.plant_code[i] = (response.data.data.part_storage_location[i].plant_code == null) ? '' : response.data.data.part_storage_location[i].plant_code
					  context.prcMcrItemSloc.storage_location[i] = (response.data.data.part_storage_location[i].location_code == null) ? '' : response.data.data.part_storage_location[i].location_code
					  // context.prcMcrItemSloc.warehouse_number[i] = response.data.data.part_storage_location[i].warehouse_no
					  // context.prcMcrItemSloc.storage_type[i] = response.data.data.part_storage_location[i].storage_type_code
					  context.prcMcrItemSloc.bin_location[i] = (response.data.data.part_storage_location[i].bin_code == null) ? '' : response.data.data.part_storage_location[i].bin_code
					  context.prcMcrItemSloc.del_flag[i] = (response.data.data.part_storage_location[i].del_flag == null) ? '' : response.data.data.part_storage_location[i].del_flag
					  context.prcMcrItemSloc.status[i] = 'old';
					}

					// GET SALES

					context.prcMcrItemSales.status = [];
					context.prcMcrItemSales.sls_plant_code = [];
					context.prcMcrItemSales.sales_org = [];
					context.prcMcrItemSales.distr_channel = [];
					context.prcMcrItemSales.sales_unit = [];
					context.prcMcrItemSales.division = [];
					// context.prcMcrItemSales.tax_cat = [];
					context.prcMcrItemSales.tax_class = [];
					context.prcMcrItemSales.gen_item_cat_grp = [];
					context.prcMcrItemSales.account_assign_grp = [];
					context.prcMcrItemSales.item_cat_grp = [];
					context.prcMcrItemSales.trans_group = [];
					context.prcMcrItemSales.loading_group = [];
					context.prcMcrItemSales.profit_center = [];
					context.prcMcrItemSales.status = [];
					context.prcMcrItemSales.del_flag = [];

					for (var i = 0; i < response.data.data.part_sales.length; i++) {
						context.prcMcrItemSales.sls_plant_code[i] = (response.data.data.part_sales[i].plant_code == null) ? '' : response.data.data.part_sales[i].plant_code
						context.prcMcrItemSales.sales_org[i] = (response.data.data.part_sales[i].sales_org_code == null) ? '' : response.data.data.part_sales[i].sales_org_code
						context.prcMcrItemSales.distr_channel[i] = (response.data.data.part_sales[i].distr_channel_code == null) ? '' : response.data.data.part_sales[i].distr_channel_code
						context.prcMcrItemSales.sales_unit[i] = (response.data.data.part_sales[i].sales_unit == null) ? '' : response.data.data.part_sales[i].sales_unit
						context.prcMcrItemSales.division[i] = (response.data.data.part_sales[i].division_code == null) ? '' : response.data.data.part_sales[i].division_code
						// context.prcMcrItemSales.tax_cat[i] = response.data.data.part_sales[i].tax_cat_code
						context.prcMcrItemSales.tax_class[i] = (response.data.data.part_sales[i].tax_class_code == null) ? '' : response.data.data.part_sales[i].tax_class_code
						context.prcMcrItemSales.gen_item_cat_grp[i] = (response.data.data.part_sales[i].gen_item_cat_grp_code == null) ? '' : response.data.data.part_sales[i].gen_item_cat_grp_code
						context.prcMcrItemSales.account_assign_grp[i] = (response.data.data.part_sales[i].acc_ass_grp_code == null) ? '' : response.data.data.part_sales[i].acc_ass_grp_code
						context.prcMcrItemSales.item_cat_grp[i] = (response.data.data.part_sales[i].item_cat_grp_code == null) ? '' : response.data.data.part_sales[i].item_cat_grp_code
						context.prcMcrItemSales.trans_group[i] = (response.data.data.part_sales[i].trans_group_code == null) ? '' : response.data.data.part_sales[i].trans_group_code
						context.prcMcrItemSales.loading_group[i] = response.data.data.part_sales[i].loading_group_code
						context.prcMcrItemSales.profit_center[i] = (response.data.data.part_sales[i].profit_center_code == null) ? '' : response.data.data.part_sales[i].profit_center_code
						context.prcMcrItemSales.del_flag[i] = (response.data.data.part_sales[i].del_flag == null) ? '' : response.data.data.part_sales[i].del_flag
						context.prcMcrItemSales.status[i] = 'old';
	                }

	                // GET MRP

	                context.prcMcrItemMrp.status = [];
	                context.prcMcrItemMrp.plant_mrp = [];
					context.prcMcrItemMrp.abc_indicator = [];
					context.prcMcrItemMrp.mrp_type = [];
					context.prcMcrItemMrp.mrp_controller = [];
					context.prcMcrItemMrp.lot_size = [];
					context.prcMcrItemMrp.min = [];
					context.prcMcrItemMrp.max = [];
					context.prcMcrItemMrp.procurement_type = [];
					context.prcMcrItemMrp.special_proc = [];
					context.prcMcrItemMrp.max_stock = [];
					context.prcMcrItemMrp.fixed_lot = [];
					context.prcMcrItemMrp.ord_costs = [];
					context.prcMcrItemMrp.reorder_point = [];
					context.prcMcrItemMrp.status = [];
					context.prcMcrItemMrp.del_flag = [];

	                for (var i = 0; i < response.data.data.part_mrp.length; i++) {
						context.prcMcrItemMrp.plant_mrp[i] = (response.data.data.part_mrp[i].plant_code == null) ? '' : response.data.data.part_mrp[i].plant_code
						context.prcMcrItemMrp.abc_indicator[i] = (response.data.data.part_mrp[i].abc_indicator_code == null) ? '' : response.data.data.part_mrp[i].abc_indicator_code
						context.prcMcrItemMrp.mrp_type[i] = (response.data.data.part_mrp[i].mrp_type_code == null) ? '' : response.data.data.part_mrp[i].mrp_type_code
						context.prcMcrItemMrp.mrp_controller[i] = (response.data.data.part_mrp[i].mrp_controller_code == null) ? '' : response.data.data.part_mrp[i].mrp_controller_code
						context.prcMcrItemMrp.lot_size[i] = (response.data.data.part_mrp[i].lot_size_code == null) ? '' : response.data.data.part_mrp[i].lot_size_code
						context.prcMcrItemMrp.min[i] = (response.data.data.part_mrp[i].min == null) ? '' : response.data.data.part_mrp[i].min
						context.prcMcrItemMrp.max[i] = (response.data.data.part_mrp[i].max == null) ? '' : response.data.data.part_mrp[i].max
						context.prcMcrItemMrp.procurement_type[i] = (response.data.data.part_mrp[i].procurement_type_code == null) ? '' : response.data.data.part_mrp[i].procurement_type_code
						context.prcMcrItemMrp.special_proc[i] = (response.data.data.part_mrp[i].special_proc_type_code == null) ? '' : response.data.data.part_mrp[i].special_proc_type_code
						context.prcMcrItemMrp.max_stock[i] = (response.data.data.part_mrp[i].max_stock == null) ? '' : response.data.data.part_mrp[i].max_stock
						context.prcMcrItemMrp.fixed_lot[i] = (response.data.data.part_mrp[i].fixed_lot == null) ? '' : response.data.data.part_mrp[i].fixed_lot
						context.prcMcrItemMrp.ord_costs[i] = (response.data.data.part_mrp[i].ord_costs == null) ? '' : response.data.data.part_mrp[i].ord_costs
						context.prcMcrItemMrp.reorder_point[i] = (response.data.data.part_mrp[i].reorder_point == null) ? '' : response.data.data.part_mrp[i].reorder_point
						context.prcMcrItemMrp.del_flag[i] = (response.data.data.part_mrp[i].del_flag == null) ? '' : response.data.data.part_mrp[i].del_flag
						context.prcMcrItemMrp.status[i] = 'old';
					}

					// GET ACCOUNTING

					context.prcMcrItemAccounting.status = []; 
					context.prcMcrItemAccounting.plant_accounting = []; 
					context.prcMcrItemAccounting.valuation_class = []; 
					context.prcMcrItemAccounting.valuation_category = []; 
					context.prcMcrItemAccounting.valuation_type = []; 
					context.prcMcrItemAccounting.price_control = []; 
					context.prcMcrItemAccounting.price_determination = []; 
					context.prcMcrItemAccounting.standard_price = []; 
					context.prcMcrItemAccounting.price_unit = []; 
					context.prcMcrItemAccounting.price_reference = [];
					context.prcMcrItemAccounting.status = [];
					context.prcMcrItemAccounting.del_flag = [];

					for (var i = 0; i < response.data.data.part_accounting.length; i++) {
						context.prcMcrItemAccounting.plant_accounting[i] = (response.data.data.part_accounting[i].plant_code == null) ? '' : response.data.data.part_accounting[i].plant_code
						context.prcMcrItemAccounting.valuation_class[i] = (response.data.data.part_accounting[i].valuation_class_code == null) ? '' : response.data.data.part_accounting[i].valuation_class_code
						context.prcMcrItemAccounting.valuation_category[i] = (response.data.data.part_accounting[i].valuation_category_code == null) ? '' : response.data.data.part_accounting[i].valuation_category_code
						context.prcMcrItemAccounting.valuation_type[i] = (response.data.data.part_accounting[i].valuation_type_code == null) ? '' : response.data.data.part_accounting[i].valuation_type_code
						context.prcMcrItemAccounting.price_control[i] = (response.data.data.part_accounting[i].price_control_code == null) ? '' : response.data.data.part_accounting[i].price_control_code
						context.prcMcrItemAccounting.price_determination[i] = (response.data.data.part_accounting[i].price_determination == null) ? '' : response.data.data.part_accounting[i].price_determination
						context.prcMcrItemAccounting.standard_price[i] = (response.data.data.part_accounting[i].standard_price == null) ? '' : response.data.data.part_accounting[i].standard_price
						context.prcMcrItemAccounting.price_unit[i] = (response.data.data.part_accounting[i].price_unit == null) ? '' : response.data.data.part_accounting[i].price_unit
						context.prcMcrItemAccounting.price_reference[i] = (response.data.data.part_accounting[i].price_reference == null) ? '' : response.data.data.part_accounting[i].price_reference
						context.prcMcrItemAccounting.del_flag[i] = (response.data.data.part_accounting[i].del_flag == null) ? '' : response.data.data.part_accounting[i].del_flag
						context.prcMcrItemAccounting.status[i] = 'old';
					}

					// GET PURCHASING

					context.prcMcrItemPurchasing.status = [];
					context.prcMcrItemPurchasing.plant_purchasing = [];
					context.prcMcrItemPurchasing.purchasing_group = [];
					context.prcMcrItemPurchasing.order_unit = [];
					context.prcMcrItemPurchasing.planned_delivery = [];
					context.prcMcrItemPurchasing.status = [];
					context.prcMcrItemPurchasing.del_flag = [];

					for (var i = 0; i < response.data.data.part_purchasing.length; i++) {
						context.prcMcrItemPurchasing.plant_purchasing[i] = (response.data.data.part_purchasing[i].plant_code == null) ? '' : response.data.data.part_purchasing[i].plant_code
						context.prcMcrItemPurchasing.purchasing_group[i] = (response.data.data.part_purchasing[i].purchasing_group_code == null) ? '' : response.data.data.part_purchasing[i].purchasing_group_code
						context.prcMcrItemPurchasing.order_unit[i] = (response.data.data.part_purchasing[i].order_unit == null) ? '' : response.data.data.part_purchasing[i].order_unit
						context.prcMcrItemPurchasing.planned_delivery[i] = (response.data.data.part_purchasing[i].planned_delivery == null) ? '' : response.data.data.part_purchasing[i].planned_delivery
						context.prcMcrItemPurchasing.del_flag[i] = (response.data.data.part_purchasing[i].del_flag == null) ? '' : response.data.data.part_purchasing[i].del_flag
						context.prcMcrItemPurchasing.status[i] = 'old';
					}

					// GET COSTING

					context.prcMcrItemCosting.status = [];
					context.prcMcrItemCosting.plant_costing = [];
					context.prcMcrItemCosting.overhead_group = [];
					context.prcMcrItemCosting.costing_lot_size = [];
					context.prcMcrItemCosting.status = [];
					context.prcMcrItemCosting.del_flag = [];

					for (var i = 0; i < response.data.data.part_costing.length; i++) {
						context.prcMcrItemCosting.plant_costing[i] = (response.data.data.part_costing[i].plant_code == null) ? '' : response.data.data.part_costing[i].plant_code
						context.prcMcrItemCosting.overhead_group[i] = (response.data.data.part_costing[i].overhead_group == null) ? '' : response.data.data.part_costing[i].overhead_group
						context.prcMcrItemCosting.costing_lot_size[i] = (response.data.data.part_costing[i].costing_lot_size == null) ? '' : response.data.data.part_costing[i].costing_lot_size
						context.prcMcrItemCosting.del_flag[i] = (response.data.data.part_costing[i].del_flag == null) ? '' : response.data.data.part_costing[i].del_flag
						context.prcMcrItemCosting.status[i] = 'old';
					}

					// GET EQUIPMENT

					context.prcMcrItemEquipment.plant_equipment_code = []; 
					context.prcMcrItemEquipment.equipment_code = []; 
					context.prcMcrItemEquipment.qty_installed = []; 
					context.prcMcrItemEquipment.drawing_number = []; 
					context.prcMcrItemEquipment.status = []; 
					context.prcMcrItemEquipment.del_flag = []; 
					context.drawingAttachment = [];

					for (var i = 0; i < response.data.data.part_equipment_code.length; i++) {
						context.prcMcrItemEquipment.plant_equipment_code[i] = (response.data.data.part_equipment_code[i].plant_code == null) ? '' : response.data.data.part_equipment_code[i].plant_code
						context.prcMcrItemEquipment.equipment_code[i] = (response.data.data.part_equipment_code[i].equipment_code == null) ? '' : response.data.data.part_equipment_code[i].equipment_code
						context.prcMcrItemEquipment.qty_installed[i] = (response.data.data.part_equipment_code[i].qty_installed == null) ? '' : response.data.data.part_equipment_code[i].qty_installed
						context.prcMcrItemEquipment.drawing_number[i] = (response.data.data.part_equipment_code[i].drawing_number == null) ? '' : response.data.data.part_equipment_code[i].drawing_number
						context.prcMcrItemEquipment.del_flag[i] = (response.data.data.part_equipment_code[i].del_flag == null) ? '' : response.data.data.part_equipment_code[i].del_flag
						context.prcMcrItemEquipment.status[i] = 'old';
						context.drawingAttachment[i] = response.data.data.part_equipment_code[i].file_name;

						// for po text
						context.prcMcrItemPoText.equipment = (response.data.data.part_equipment_code == null) ? '' : response.data.data.part_equipment_code
					}

					context.prcMcrItemAttachment.file_attachment_code = [];

                  	for (var i = 0; i < response.data.data.part_attachment.length; i++) {
						context.prcMcrItemAttachment.file_attachment_code[i] = (response.data.data.part_attachment[i].file_attachment_code == null) ? '' : response.data.data.part_attachment[i].file_attachment_code
					}

					// GET KEYWORD

					context.prcMcrItemKeyword.keyword = [];

					for (var i = 0; i < response.data.data.part_keyword.length; i++) {
						context.prcMcrItemKeyword.keyword[i] = (response.data.data.part_keyword[i].keyword == null) ? '' : response.data.data.part_keyword[i].keyword
					}

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
            },
            getValuationClassByAccCatRef() {
            	let context = this;

		    	Api(context, valuationClass.getByAccCatRef(this.input.prc_mtype_code))
		        .onSuccess(function(response) {
					context.valuationClass = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.valuationClass = [];
		          }
		        })
		        .call();
            },
            getValuationTypeByAccCatRef() {
            	let context = this;

            	Api(context, valuationType.getByAccCatRef(this.input.prc_mtype_code))
		        .onSuccess(function(response) {
					context.valuationType = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.valuationType = [];
		          }
		        })
		        .call();
            },
            changePriceControl() {
            	let context = this;

            	Api(context, materialType.getByMtypeCode(context.input.prc_mtype_code)).onSuccess(function(response) {    
                    context.priceControl = response.data.data[0].price_control_code;

                    context.prcMcrItemAccounting.price_control.forEach(function(item, index) {
                    	context.prcMcrItemAccounting.price_control[index] = response.data.data[0].price_control_code;
                    })
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = '';
                    }
                })
                .call() 
            },
     		dependOnPlant() {
     			if (this.prcMcrItemPlant.plant.length > 0) {
     				if (this.prcMcrItemPlant.plant[0] != '') {

		     			let context = this;

		     			// GET MRP CONTROLLER
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							Api(context, mrpController.getByPlant(item)).onSuccess(function(response) {
								context.mrpController[item] = response.data.data;
								context.$forceUpdate()
							})
							.call();
		     			})

		     			// GET LOCATION
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							Api(context, locationCode.getByPlant(item)).onSuccess(function(response) {
								context.locationCode[item] = response.data.data;
								context.$forceUpdate()
							})
							.call();
		     			})

		     			// GET SPECIAL PROCUREMENT TYPE
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							Api(context, specialProcurementType.getByPlant(item)).onSuccess(function(response) {
								context.specialProcurementType[item] = response.data.data;
								context.$forceUpdate()
							})
							.call();
		     			})
     				}
     			}
     		},
            getParameterMcr() {
            	this.requestCalls++;

		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
					context.itemType            = response.data.data.item_type;
					context.fabNonFab           = response.data.data.fab_non_fab;
					context.bomNonBom           = response.data.data.bom_non_bom;
					context.criticalNonCritical = response.data.data.critical_non_critical;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
		    },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getSourceType() {
            	let context = this;               
                Api(context, sourceType.get(null,{per_page: 'none'})).onSuccess(function(response) {   
                    context.sourceType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.sourceType = [];
                    }
                })
                .call()
            },
            getOverheadGroup() {
          		let data = {
          			'0': {
          				code: 'SAP1',
	          			description: 'Overhead Group 1'
          			},
          			'1': {
          				code: 'SAP2',
	          			description: 'Overhead Group 2'
          			},
          		}

          		this.overheadGroup = data;
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            // getValuationType() {
            //     let context = this;               
            //     Api(context, valuationType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.valuationType = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.valuationType = [];
            //         }
            //     })
            //     .call()
            // },
            // getPriceControl() {
            //     let context = this;               
            //     Api(context, priceControl.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.priceControl = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.priceControl = [];
            //         }
            //     })
            //     .call()
            // },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            // getWarehouse() {
            //     let context = this;               
            //     Api(context, warehouse.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.warehouse = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.warehouse = [];
            //         }
            //     })
            //     .call()
            // },
            // getStorageType() {
            //     let context = this;               
            //     Api(context, storageType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.storageType = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.storageType = [];
            //         }
            //     })
            //     .call()
            // },
            // getLocationCode() {
            //     let context = this;               
            //     Api(context, locationCode.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.locationCode = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.locationCode = [];
            //         }
            //     })
            //     .call()
            // },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get()).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            addPurchasing() {
            	this.accordion.purchasing.show = "show";

                if (this.prcMcrItemPurchasing.plant_purchasing.length == 0) {
                    this.prcMcrItemPurchasing.plant_purchasing.push('')
                    this.prcMcrItemPurchasing.purchasing_group.push('')
                    this.prcMcrItemPurchasing.order_unit.push('')
                    this.prcMcrItemPurchasing.planned_delivery.push('')
                    this.prcMcrItemPurchasing.status.push('new')
                    this.prcMcrItemPurchasing.del_flag.push('')
                } else if (this.prcMcrItemPurchasing.plant_purchasing.length > 0) {
                    if (this.prcMcrItemPurchasing.plant_purchasing[this.prcMcrItemPurchasing.plant_purchasing.length-1] != '' &&
                        this.prcMcrItemPurchasing.purchasing_group[this.prcMcrItemPurchasing.purchasing_group.length-1] != '') {
                        
                        this.prcMcrItemPurchasing.plant_purchasing.push('')
                        this.prcMcrItemPurchasing.purchasing_group.push('')
                        this.prcMcrItemPurchasing.order_unit.push('')
                        this.prcMcrItemPurchasing.planned_delivery.push('')
                        this.prcMcrItemPurchasing.status.push('new')
                        this.prcMcrItemPurchasing.del_flag.push('')
                    } else {
                        this.completeDataNotify('purchasing')
                    }
                }
            },
            removePurchasing(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemPurchasing.plant_purchasing.splice(key, 1)
		                this.prcMcrItemPurchasing.purchasing_group.splice(key, 1)
		                this.prcMcrItemPurchasing.order_unit.splice(key, 1)
		                this.prcMcrItemPurchasing.planned_delivery.splice(key, 1)
			        }
			    });
            },
            addCosting() {
            	this.accordion.costing.show = "show";

            	if (Object.entries(this.overheadGroup).length === 0) {
            		this.getOverheadGroup();
            	}

                if (this.prcMcrItemCosting.plant_costing.length == 0) {
                    this.prcMcrItemCosting.plant_costing.push('')
                    this.prcMcrItemCosting.overhead_group.push('')
                    this.prcMcrItemCosting.costing_lot_size.push(1)
                    this.prcMcrItemCosting.status.push('new')
                    this.prcMcrItemCosting.del_flag.push('')
                } else if (this.prcMcrItemCosting.plant_costing.length > 0) {
                    if (this.prcMcrItemCosting.plant_costing[this.prcMcrItemCosting.plant_costing.length-1] != '' &&
                        this.prcMcrItemCosting.overhead_group[this.prcMcrItemCosting.overhead_group.length-1] != '') {
                        
                        this.prcMcrItemCosting.plant_costing.push('')
                        this.prcMcrItemCosting.overhead_group.push('')
                        this.prcMcrItemCosting.costing_lot_size.push(1)
                        this.prcMcrItemCosting.status.push('new')
                        this.prcMcrItemCosting.del_flag.push('')
                    } else {
                        this.completeDataNotify('costing')
                    }
                }
            },
            removeCosting(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemCosting.plant_costing.splice(key, 1)
		                this.prcMcrItemCosting.overhead_group.splice(key, 1)
		                this.prcMcrItemCosting.costing_lot_size.splice(key, 1)
			        }
			    });
            },
            addMpn() {
            	this.accordion.manref.show = "show";

            	if (Object.entries(this.masterManufacture).length === 0 || Object.entries(this.manrefType).length === 0) {
		            this.getManrefType();
		            this.getSourceType();
            	}

                if (this.prcMcrItemMpn.manufacture_code.length == 0) {
                    this.prcMcrItemMpn.manufacture_code.push('')
                    this.prcMcrItemMpn.mpn.push('')
                    this.prcMcrItemMpn.source_type.push('')
                    this.prcMcrItemMpn.manufacture_type.push('')
                    this.prcMcrItemMpn.manufacture_note.push('')
                    this.prcMcrItemMpn.status.push('new');
                } else if (this.prcMcrItemMpn.manufacture_code.length > 0) {
                    if (this.prcMcrItemMpn.manufacture_code[this.prcMcrItemMpn.manufacture_code.length-1] != '' &&
                        this.prcMcrItemMpn.mpn[this.prcMcrItemMpn.mpn.length-1] != '' &&
                        this.prcMcrItemMpn.source_type[this.prcMcrItemMpn.source_type.length-1] != '' &&
                        this.prcMcrItemMpn.manufacture_type[this.prcMcrItemMpn.manufacture_type.length-1] != '') {
                        
                        this.prcMcrItemMpn.manufacture_code.push('')
                        this.prcMcrItemMpn.mpn.push('')
                        this.prcMcrItemMpn.source_type.push('')
                        this.prcMcrItemMpn.manufacture_type.push('')
                        this.prcMcrItemMpn.manufacture_note.push('')
                        this.prcMcrItemMpn.status.push('new');
                    } else {
                        this.completeDataNotify('mpn')
                    }
                }
            },
            removeMpn(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemMpn.manufacture_code.splice(key, 1)
		                this.prcMcrItemMpn.mpn.splice(key, 1)
		                this.prcMcrItemMpn.manufacture_type.splice(key, 1)
		                this.prcMcrItemMpn.manufacture_note.splice(key, 1)
			        }
			    });
            },
            addEquipment() {
            	this.accordion.equipment.show = "show";

                if (this.prcMcrItemEquipment.plant_equipment_code.length == 0) {
                    this.prcMcrItemEquipment.plant_equipment_code.push('')
                    this.prcMcrItemEquipment.equipment_code.push('')
                    this.prcMcrItemEquipment.qty_installed.push('')
                    this.prcMcrItemEquipment.drawing_number.push('')
                    this.prcMcrItemEquipment.status.push('new')
                    this.prcMcrItemEquipment.del_flag.push('')
                } else if (this.prcMcrItemEquipment.plant_equipment_code.length > 0) {
                    if (this.prcMcrItemEquipment.plant_equipment_code[this.prcMcrItemEquipment.plant_equipment_code.length-1] != '' &&
                        this.prcMcrItemEquipment.equipment_code[this.prcMcrItemEquipment.equipment_code.length-1] != '' &&
                        // this.prcMcrItemEquipment.qty_installed[this.prcMcrItemEquipment.qty_installed.length-1] != '' &&
                        this.prcMcrItemEquipment.drawing_number[this.prcMcrItemEquipment.drawing_number.length-1] != '') {
                        
                        this.prcMcrItemEquipment.plant_equipment_code.push('')
                        this.prcMcrItemEquipment.equipment_code.push('')
                        this.prcMcrItemEquipment.qty_installed.push('')
                        this.prcMcrItemEquipment.drawing_number.push('')
                        this.prcMcrItemEquipment.status.push('new')
                        this.prcMcrItemEquipment.del_flag.push('')
                    } else {
                        this.completeDataNotify('equipment')
                    }
                }
            },
            removeEquipment(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemEquipment.plant_equipment_code.splice(key, 1)
		                this.prcMcrItemEquipment.equipment_code.splice(key, 1)
		                this.prcMcrItemEquipment.qty_installed.splice(key, 1)
		                this.prcMcrItemEquipment.drawing_number.splice(key, 1)
			        }
			    });         
            },
            addAttachment() {
            	this.accordion.attDoc.show = "show";

                if (this.prcMcrItemAttachment.file_attachment_code.length == 0) {
                    this.prcMcrItemAttachment.file_attachment_code.push('')
                } else if (this.prcMcrItemAttachment.file_attachment_code.length > 0) {
                    if (this.prcMcrItemAttachment.file_attachment_code[this.prcMcrItemAttachment.file_attachment_code.length-1] == '') {
                        this.completeDataNotify('attachment')
                    } else {
                        this.prcMcrItemAttachment.file_attachment_code.push('')
                    }
                }
            },
            removeAttachment(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemAttachment.file_attachment_code.splice(key, 1)
			        }
			    });
            },
            addKeyword() {
            	this.accordion.keyword.show = "show";

                if (this.prcMcrItemKeyword.keyword.length == 0) {
                    this.prcMcrItemKeyword.keyword.push('')
                } else if (this.prcMcrItemKeyword.keyword.length > 0) {
                    if (this.prcMcrItemKeyword.keyword[this.prcMcrItemKeyword.keyword.length-1] == '') {
                        this.completeDataNotify('keyword')
                    } else {
                        this.prcMcrItemKeyword.keyword.push('')
                    }
                }
            },
            removeKeyword(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemKeyword.keyword.splice(key, 1)
			        }
			    });
            },
            addPlant() {
            	this.accordion.plant.show = "show";

                if (this.prcMcrItemPlant.plant.length == 0) {
                    this.prcMcrItemPlant.plant.push('')
                    this.prcMcrItemPlant.status.push('new')
                    this.prcMcrItemPlant.del_flag.push('')
                } else if (this.prcMcrItemPlant.plant.length > 0) {
                    if (this.prcMcrItemPlant.plant[this.prcMcrItemPlant.plant.length-1] != '') {
                        this.prcMcrItemPlant.plant.push('')
                        this.prcMcrItemPlant.status.push('new')
                        this.prcMcrItemPlant.del_flag.push('')
                    } else {
                        this.completeDataNotify('plant')
                    }
                }
            },
            removePlant(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemPlant.plant.splice(key, 1)
			        }
			    });
            },
            addSloc() {
            	this.accordion.sloc.show = "show";

                if (this.prcMcrItemSloc.plant_code.length == 0) {
                    this.prcMcrItemSloc.plant_code.push('')
                    this.prcMcrItemSloc.storage_location.push('')
                    // this.prcMcrItemSloc.warehouse_number.push('')
                    // this.prcMcrItemSloc.storage_type.push('')
  					this.prcMcrItemSloc.bin_location.push('')
  					this.prcMcrItemSloc.status.push('new');
  					this.prcMcrItemSloc.del_flag.push('');
                } else if (this.prcMcrItemSloc.plant_code.length > 0) {
                    if (this.prcMcrItemSloc.plant_code[this.prcMcrItemSloc.plant_code.length-1] != '' &&
                        this.prcMcrItemSloc.storage_location[this.prcMcrItemSloc.storage_location.length-1] != '') {
                        
                        this.prcMcrItemSloc.plant_code.push('')
                        this.prcMcrItemSloc.storage_location.push('')
                        // this.prcMcrItemSloc.warehouse_number.push('')
                        // this.prcMcrItemSloc.storage_type.push('')
						this.prcMcrItemSloc.bin_location.push('')
						this.prcMcrItemSloc.status.push('new');
						this.prcMcrItemSloc.del_flag.push('');
                    } else {
                        this.completeDataNotify('storage_location')
                    }
                }
            },
            removeSloc(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemSloc.plant_code.splice(key, 1)
		                this.prcMcrItemSloc.storage_location.splice(key, 1)
		                this.prcMcrItemSloc.warehouse_number.splice(key, 1)
		                this.prcMcrItemSloc.storage_type.splice(key, 1)   
						this.prcMcrItemSloc.bin_location.splice(key, 1)   
		                this.prcMcrItemSloc.hapus.splice(key, 1)
			        }
			    });
            },
            addMultipleChar(key, cin_code, characteristic, view) {
				this.prcMcrItemCharValue.cin_code.splice(key + 1, 0, cin_code)
				this.prcMcrItemCharValue.characteristic.splice(key + 1, 0, characteristic)
				this.prcMcrItemCharValue.value.splice(key + 1, 0, '')
				this.prcMcrItemCharValue.view.splice(key + 1, 0, view)
				this.prcMcrItemCharValue.multipleChar.splice(key + 1, 0, '')
				this.prcMcrItemCharValue.code.splice(key + 1, 0, 1)
            },
            removeMultipleChar(key) {
				this.prcMcrItemCharValue.cin_code.splice(key, 1)
				this.prcMcrItemCharValue.characteristic.splice(key, 1)
				this.prcMcrItemCharValue.value.splice(key, 1)
				this.prcMcrItemCharValue.view.splice(key, 1)
				this.prcMcrItemCharValue.multipleChar.splice(key, 1)
				this.prcMcrItemCharValue.code.splice(key, 1)
            },
            showCharValue() {    
            	this.accordion.charValue.show = "show";

            	let context = this;

                if (context.input.prc_inc !== '' && context.input.prc_item_type_code !== '') {    
                	this.btnShowCharValue.onLoading = true;

	                Api(context, characteristicItemName.getByType(context.input.prc_inc, context.input.prc_item_type_code)).onSuccess(function(response) {    
	                    // context.characteristicItemName = response.data.data.data;

	                    context.prcMcrItemCharValue.cin_code = [];
	                    context.prcMcrItemCharValue.characteristic = [];
	                    context.prcMcrItemCharValue.value = [];
	                    context.prcMcrItemCharValue.view = [];
	                    context.prcMcrItemCharValue.multipleChar = [];
	                    context.prcMcrItemCharValue.code = [];

	                    for (var i = 0; i < response.data.data.length; i++) {
	                        context.prcMcrItemCharValue.cin_code[i] = response.data.data[i].cin_code;
	                        context.prcMcrItemCharValue.characteristic[i] = response.data.data[i].characteristic;
	                        context.prcMcrItemCharValue.view[i] = true;
	                        context.prcMcrItemCharValue.multipleChar[i] = response.data.data[i].single_or_multiple;
	                        context.prcMcrItemCharValue.code[i] = '';
	                    }
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        // context.characteristicItemName = [];
	                        context.prcMcrItemCharValue.cin_code = [];
		                    context.prcMcrItemCharValue.characteristic = [];
		                    context.prcMcrItemCharValue.view = [];
		                    context.prcMcrItemCharValue.multipleChar = [];
		                    context.prcMcrItemCharValue.code = [];
	                    }
	                }).onFinish(function() {
                        context.btnShowCharValue.onLoading = false;   
                    }).call()
                } else {
                	this.$notify({
                        message: this.tt("please_complete_data", { title: 'Item Name & Item Type' }),                  
                        type: 'danger',
                        verticalAlign: "bottom",
			            horizontalAlign: "left"
                    });
                    return false;
                }        
            },
            previewPoText() {
            	this.previewPoTextModal = true;
            },
            addMrp() {
            	this.accordion.mrp.show = "show";

            	if (Object.entries(this.abcIndicator).length === 0||
            		Object.entries(this.mrpTypes).length === 0 ||
            		Object.entries(this.lotSize).length === 0 ||
            		Object.entries(this.procurementType).length === 0) {

            		this.getAbcIndicator();
		            this.getMrpTypes();
		            this.getLotSize();
		            this.getProcurementType();
            	}

                if (this.prcMcrItemMrp.abc_indicator.length == 0) {
                    this.prcMcrItemMrp.abc_indicator.push('')
                    this.prcMcrItemMrp.mrp_type.push('')
                    this.prcMcrItemMrp.mrp_controller.push('')
                    this.prcMcrItemMrp.reorder_point.push('')
                    this.prcMcrItemMrp.lot_size.push('')
                    this.prcMcrItemMrp.min.push('')
                    this.prcMcrItemMrp.max.push('')
                    this.prcMcrItemMrp.procurement_type.push('')
                    this.prcMcrItemMrp.special_proc.push('')
                    this.prcMcrItemMrp.plant_mrp.push('')
					this.prcMcrItemMrp.max_stock.push('')
					this.prcMcrItemMrp.fixed_lot.push('')
					this.prcMcrItemMrp.ord_costs.push('')
                    this.prcMcrItemMrp.status.push('new')
                    this.prcMcrItemMrp.del_flag.push('')
                } else if (this.prcMcrItemMrp.abc_indicator.length > 0) {
                    if (this.prcMcrItemMrp.abc_indicator[this.prcMcrItemMrp.abc_indicator.length-1] != '' &&
                        this.prcMcrItemMrp.mrp_type[this.prcMcrItemMrp.mrp_type.length-1] != '' &&
                        this.prcMcrItemMrp.mrp_controller[this.prcMcrItemMrp.mrp_controller.length-1] != '' &&
                        // this.prcMcrItemMrp.reorder_point[this.prcMcrItemMrp.reorder_point.length-1] != '' &&
                        this.prcMcrItemMrp.lot_size[this.prcMcrItemMrp.lot_size.length-1] != '' &&
                        // this.prcMcrItemMrp.min[this.prcMcrItemMrp.min.length-1] != '' &&
                        // this.prcMcrItemMrp.max[this.prcMcrItemMrp.max.length-1] != '' &&
                        this.prcMcrItemMrp.procurement_type[this.prcMcrItemMrp.procurement_type.length-1] != '' &&
                        this.prcMcrItemMrp.special_proc[this.prcMcrItemMrp.special_proc.length-1] != '') {
                        
                        this.prcMcrItemMrp.abc_indicator.push('')
                        this.prcMcrItemMrp.mrp_type.push('')
                        this.prcMcrItemMrp.mrp_controller.push('')
                        this.prcMcrItemMrp.reorder_point.push('')
                        this.prcMcrItemMrp.lot_size.push('')
                        this.prcMcrItemMrp.min.push('')
                        this.prcMcrItemMrp.max.push('')
                        this.prcMcrItemMrp.procurement_type.push('')
                        this.prcMcrItemMrp.special_proc.push('')
                        this.prcMcrItemMrp.plant_mrp.push('')
                        this.prcMcrItemMrp.max_stock.push('')
						this.prcMcrItemMrp.fixed_lot.push('')
						this.prcMcrItemMrp.ord_costs.push('')
                        this.prcMcrItemMrp.status.push('new')
                        this.prcMcrItemMrp.del_flag.push('')
                    } else {
                        this.completeDataNotify('mrp')
                    }
                }
            },
            removeMrp(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemMrp.abc_indicator.splice(key, 1)
		                this.prcMcrItemMrp.mrp_type.splice(key, 1)
		                this.prcMcrItemMrp.mrp_controller.splice(key, 1)
		                this.prcMcrItemMrp.reorder_point.splice(key, 1)
		                this.prcMcrItemMrp.lot_size.splice(key, 1)
		                this.prcMcrItemMrp.min.splice(key, 1)
		                this.prcMcrItemMrp.max.splice(key, 1)
		                this.prcMcrItemMrp.procurement_type.splice(key, 1)
		                this.prcMcrItemMrp.special_proc.splice(key, 1)
		                this.prcMcrItemMrp.plant_mrp.splice(key, 1)
		                this.prcMcrItemMrp.max_stock.splice(key, 1)
						this.prcMcrItemMrp.fixed_lot.splice(key, 1)
						this.prcMcrItemMrp.ord_costs.splice(key, 1)
			        }
			    });
            },
            addAccounting() {
            	this.accordion.accounting.show = "show";

            	if (Object.entries(this.valuationCategory).length === 0) {

		            this.getValuationCategory();
		            // this.getValuationType();
		            // this.getPriceControl();
            	}

                if (this.prcMcrItemAccounting.valuation_class.length == 0) {
                    this.prcMcrItemAccounting.valuation_class.push('')
                    this.prcMcrItemAccounting.valuation_category.push('')
                    this.prcMcrItemAccounting.valuation_type.push('')
                    this.prcMcrItemAccounting.price_control.push(this.priceControl)
                    this.prcMcrItemAccounting.price_determination.push('')
                    this.prcMcrItemAccounting.standard_price.push(0)
                    this.prcMcrItemAccounting.price_unit.push(1)
                    this.prcMcrItemAccounting.price_reference.push('')
                    this.prcMcrItemAccounting.plant_accounting.push('')
                    this.prcMcrItemAccounting.status.push('new')
                    this.prcMcrItemAccounting.del_flag.push('')
                } else if (this.prcMcrItemAccounting.valuation_class.length > 0) {
                    if (this.prcMcrItemAccounting.valuation_class[this.prcMcrItemAccounting.valuation_class.length-1] != '' &&
                        // this.prcMcrItemAccounting.valuation_category[this.prcMcrItemAccounting.valuation_category.length-1] != '' &&
                        // this.prcMcrItemAccounting.valuation_type[this.prcMcrItemAccounting.valuation_type.length-1] != '' &&
                        // this.prcMcrItemAccounting.price_control[this.prcMcrItemAccounting.price_control.length-1] != '' &&
                        this.prcMcrItemAccounting.price_determination[this.prcMcrItemAccounting.price_determination.length-1] != '' &&
                        // this.prcMcrItemAccounting.standard_price[this.prcMcrItemAccounting.standard_price.length-1] != '' &&
                        this.prcMcrItemAccounting.price_unit[this.prcMcrItemAccounting.price_unit.length-1] != '' &&
                        this.prcMcrItemAccounting.price_reference[this.prcMcrItemAccounting.price_reference.length-1] != '') {
                        
                        this.prcMcrItemAccounting.valuation_class.push('')
                        this.prcMcrItemAccounting.valuation_category.push('')
                        this.prcMcrItemAccounting.valuation_type.push('')
                        this.prcMcrItemAccounting.price_control.push(this.priceControl)
                        this.prcMcrItemAccounting.price_determination.push('')
                        this.prcMcrItemAccounting.standard_price.push(0)
                        this.prcMcrItemAccounting.price_unit.push(1)
                        this.prcMcrItemAccounting.price_reference.push('')
                        this.prcMcrItemAccounting.plant_accounting.push('')
                        this.prcMcrItemAccounting.status.push('new')
                        this.prcMcrItemAccounting.del_flag.push('')
                    } else {
                        this.completeDataNotify('accounting')
                    }
                }
            },
            removeAccounting(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemAccounting.valuation_class.splice(key, 1)
		                this.prcMcrItemAccounting.valuation_category.splice(key, 1)
		                this.prcMcrItemAccounting.valuation_type.splice(key, 1)
		                this.prcMcrItemAccounting.price_control.splice(key, 1)
		                this.prcMcrItemAccounting.price_determination.splice(key, 1)
		                this.prcMcrItemAccounting.standard_price.splice(key, 1)
		                this.prcMcrItemAccounting.price_unit.splice(key, 1)
		                this.prcMcrItemAccounting.price_reference.splice(key, 1)
		                this.prcMcrItemAccounting.plant_accounting.splice(key, 1)
			        }
			    });
            },
            addSales() {
            	this.accordion.sales.show = "show";

            	if (Object.entries(this.salesOrganization).length === 0 ||
            		Object.entries(this.distributionChannel).length === 0 || 
            		Object.entries(this.division).length === 0 || 
            		Object.entries(this.taxClassification).length === 0 || 
            		Object.entries(this.itemCategoryGroup).length === 0|| 
            		Object.entries(this.accountAssignmentGroup).length === 0 || 
            		Object.entries(this.transportationGroup).length === 0 || 
            		Object.entries(this.loadingGroup).length === 0) {

            		this.getSalesOrganization();
		            this.getDistributionChannel();
		            this.getDivision();
		            this.getTaxClassification();
		            this.getItemCategoryGroup();
		            this.getAccountAssignmentGroup();
		            this.getTransportationGroup();
		            this.getLoadingGroup();
            	}

                if (this.prcMcrItemSales.sales_org.length == 0) {
                    this.prcMcrItemSales.sales_org.push('')
                    this.prcMcrItemSales.distr_channel.push('')
                    this.prcMcrItemSales.sales_unit.push('')
                    this.prcMcrItemSales.division.push('')
                    // this.prcMcrItemSales.tax_cat.push('')
                    this.prcMcrItemSales.tax_class.push('')
                    this.prcMcrItemSales.gen_item_cat_grp.push('')
                    this.prcMcrItemSales.account_assign_grp.push('')
                    this.prcMcrItemSales.item_cat_grp.push('')
                    this.prcMcrItemSales.trans_group.push('')
                    this.prcMcrItemSales.loading_group.push('')
                    this.prcMcrItemSales.profit_center.push('')
                    this.prcMcrItemSales.sls_plant_code.push('')
                    this.prcMcrItemSales.status.push('new')
                    this.prcMcrItemSales.del_flag.push('')
                } else if (this.prcMcrItemSales.sales_org.length > 0) {
                    if (this.prcMcrItemSales.sales_org[this.prcMcrItemSales.sales_org.length-1] != '' &&
                        this.prcMcrItemSales.distr_channel[this.prcMcrItemSales.distr_channel.length-1] != '' &&
                        this.prcMcrItemSales.sales_unit[this.prcMcrItemSales.sales_unit.length-1] != '' &&
                        this.prcMcrItemSales.division[this.prcMcrItemSales.division.length-1] != '' &&
                        // this.prcMcrItemSales.tax_cat[this.prcMcrItemSales.tax_cat.length-1] != '' &&
                        this.prcMcrItemSales.tax_class[this.prcMcrItemSales.tax_class.length-1] != '' &&
                        this.prcMcrItemSales.gen_item_cat_grp[this.prcMcrItemSales.gen_item_cat_grp.length-1] != '' &&
                        this.prcMcrItemSales.account_assign_grp[this.prcMcrItemSales.account_assign_grp.length-1] != '' &&
                        this.prcMcrItemSales.item_cat_grp[this.prcMcrItemSales.item_cat_grp.length-1] != '' &&
                        this.prcMcrItemSales.trans_group[this.prcMcrItemSales.trans_group.length-1] != '' &&
                        this.prcMcrItemSales.loading_group[this.prcMcrItemSales.loading_group.length-1] != '' &&
                        this.prcMcrItemSales.profit_center[this.prcMcrItemSales.trans_group.length-1] != '') {
                        
                        this.prcMcrItemSales.sales_org.push('')
                        this.prcMcrItemSales.distr_channel.push('')
                        this.prcMcrItemSales.sales_unit.push('')
                        this.prcMcrItemSales.division.push('')
                        // this.prcMcrItemSales.tax_cat.push('')
                        this.prcMcrItemSales.tax_class.push('')
                        this.prcMcrItemSales.gen_item_cat_grp.push('')
                        this.prcMcrItemSales.account_assign_grp.push('')
                        this.prcMcrItemSales.item_cat_grp.push('')
                        this.prcMcrItemSales.trans_group.push('')
                        this.prcMcrItemSales.loading_group.push('')
                        this.prcMcrItemSales.profit_center.push('')
                        this.prcMcrItemSales.sls_plant_code.push('')
                        this.prcMcrItemSales.status.push('new')
                        this.prcMcrItemSales.del_flag.push('')
                    } else {
                        this.completeDataNotify('sales')
                    }
                }                
            },
            removeSales(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemSales.sales_org.splice(key, 1)
		                this.prcMcrItemSales.distr_channel.splice(key, 1)
		                this.prcMcrItemSales.sales_unit.splice(key, 1)
		                this.prcMcrItemSales.division.splice(key, 1)
		                // this.prcMcrItemSales.tax_cat.splice(key, 1)
		                this.prcMcrItemSales.tax_class.splice(key, 1)
		                this.prcMcrItemSales.gen_item_cat_grp.splice(key, 1)
		                this.prcMcrItemSales.account_assign_grp.splice(key, 1)
		                this.prcMcrItemSales.item_cat_grp.splice(key, 1)
		                this.prcMcrItemSales.trans_group.splice(key, 1)
		                this.prcMcrItemSales.loading_group.splice(key, 1)
		                this.prcMcrItemSales.profit_center.splice(key, 1)
		                this.prcMcrItemSales.sls_plant_code.splice(key, 1)
			        }
			    });
            },
            changeFileAttachmentName(files) {
                this.prcMcrItemAttachment.file_attachment_code[this.prcMcrItemAttachment.file_attachment_code.length-1] = files[0]                
            },
            save(save) {
                let context = this;
                let formData = new FormData();

            	if (context.$route.params.mcr_type == 'B4') {
            		formData.append('mcr_code', context.mcr_code)
                    formData.append('material_number', context.input.material_number)
                    formData.append('prc_inc', context.input.prc_inc)
                    formData.append('prc_item_type_code', context.input.prc_item_type_code)
                    formData.append('prc_nsc', context.input.prc_nsc)
                    formData.append('prc_uom_code', context.input.prc_uom_code)
                    formData.append('prc_mtype_code', context.input.prc_mtype_code)
                    formData.append('prc_mgroup_code', context.input.prc_nsc)
                    formData.append('prc_fab_non_fab', context.input.prc_fab_non_fab)
                    formData.append('prc_sos_header', context.input.prc_sos_header)
                    formData.append('prc_bom_non_bom', context.input.prc_bom_non_bom)
                    formData.append('prc_critical_non_critical', context.input.prc_critical_non_critical)
            	} else {
            		if (context.input.prc_inc != '' &&
	                    context.input.prc_item_type_code != '' &&
	                    context.input.prc_nsc != '' &&
	                    context.input.prc_uom_code != '' &&
	                    context.input.prc_mtype_code != '' &&
	                    // context.input.prc_catalog_type_code != '' &&
	                    // context.input.prc_mgroup_code != '' &&
	                    context.input.prc_fab_non_fab != '' &&
	                    // context.input.prc_sos_header != '' &&
	                    context.input.prc_bom_non_bom != '' &&
	                    context.input.prc_critical_non_critical != '') {

	                    formData.append('mcr_code', context.mcr_code)
	                    formData.append('material_number', context.input.material_number)
	                    formData.append('prc_inc', context.input.prc_inc)
	                    formData.append('prc_item_type_code', context.input.prc_item_type_code)
	                    formData.append('prc_nsc', context.input.prc_nsc)
	                    formData.append('prc_uom_code', context.input.prc_uom_code)
	                    formData.append('prc_mtype_code', context.input.prc_mtype_code)
	                    // formData.append('prc_catalog_type_code', context.input.prc_catalog_type_code)
	                    // formData.append('prc_mgroup_code', context.input.prc_mgroup_code)
	                    formData.append('prc_mgroup_code', context.input.prc_nsc)
	                    formData.append('prc_fab_non_fab', context.input.prc_fab_non_fab)
	                    formData.append('prc_sos_header', context.input.prc_sos_header)
	                    formData.append('prc_bom_non_bom', context.input.prc_bom_non_bom)
	                    formData.append('prc_critical_non_critical', context.input.prc_critical_non_critical)

	                } else {
	                    this.completeDataNotify('basic_data_&_specification')
	                    return false;
	                }
            	}    

                // CHARACTERISTIC & VALUE // mandatory

            	if (context.prcMcrItemCharValue.cin_code.length) {
            		for (var i=0; i<context.prcMcrItemCharValue.cin_code.length; i++) {
						formData.append('cin_code['+i+']', context.prcMcrItemCharValue.cin_code[i])
						formData.append('characteristic_value['+i+']', (context.prcMcrItemCharValue.value[i] != undefined) ? context.prcMcrItemCharValue.value[i] : '')
						formData.append('view['+i+']', (context.prcMcrItemCharValue.view[i] == true ? 'checked' : ''))
						formData.append('code['+i+']', context.prcMcrItemCharValue.code[i])
					}
            	} else {
            		context.requiredTabNotify('characteristic_value');
					return false;
            	}

                // POT TEXT MEMO

            	if (context.input.prc_po_text_memo != '') {
            		formData.append('prc_po_text_memo', context.input.prc_po_text_memo)
            	} else {
					formData.append('prc_po_text_memo', '')
            	}

            	// MANUFACTURE // not mandatory

            	if (context.prcMcrItemMpn.manufacture_code.length) {
                  for (var i=0; i<context.prcMcrItemMpn.manufacture_code.length; i++) {
                      if (context.prcMcrItemMpn.manufacture_code[i] != '' &&
                          context.prcMcrItemMpn.mpn[i] != '' &&
                          context.prcMcrItemMpn.source_type[i] != '' &&
                          context.prcMcrItemMpn.manufacture_type[i] != '') {
                         
                          formData.append('prc_manufacture_code['+i+']', context.prcMcrItemMpn.manufacture_code[i])
                          formData.append('prc_mpn['+i+']', context.prcMcrItemMpn.mpn[i])
                          formData.append('prc_source_type['+i+']', context.prcMcrItemMpn.source_type[i])
                          formData.append('prc_manufacture_type['+i+']', context.prcMcrItemMpn.manufacture_type[i])
                          formData.append('prc_manufacture_note['+i+']', context.prcMcrItemMpn.manufacture_note[i])
                          formData.append('prc_manufacture_status['+i+']', context.prcMcrItemMpn.status[i])
                      } else {
                          context.completeDataNotify('mpn')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_manufacture_code', '')
                  formData.append('prc_mpn', '')
                  formData.append('prc_source_type', '')
                  formData.append('prc_manufacture_type', '')
                  formData.append('prc_manufacture_note', '')
                  formData.append('prc_manufacture_status', '')
                }

                // PLANT // mandatory
                    
                if (context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemPlant.plant.length; i++) {
                      if (context.prcMcrItemPlant.plant[i] != '') {
                          formData.append('prc_plant_code['+i+']', context.prcMcrItemPlant.plant[i])
                          formData.append('prc_plant_status['+i+']', context.prcMcrItemPlant.status[i])
                          formData.append('prc_plant_del_flag['+i+']', context.prcMcrItemPlant.del_flag[i])
                      } else {
                          context.completeDataNotify('plant')
                          return false;
                      }
                  }
                } else {
                  // formData.append('prc_plant_code', '')
                  context.requiredTabNotify('plant');
                  return false;
                }

                // STORAGE LOCATION

                if (context.prcMcrItemSloc.storage_location.length) {
                  for (var i=0; i<context.prcMcrItemSloc.storage_location.length; i++) {
                      if (context.prcMcrItemSloc.storage_location[i] != ''
                          // context.prcMcrItemSloc.warehouse_number[i] != '' &&
                          // context.prcMcrItemSloc.storage_type[i] != '' &&
                          // context.prcMcrItemSloc.bin_location[i] != ''
                         ) {
                         
                          formData.append('prc_plant_sloc['+i+']', context.prcMcrItemSloc.plant_code[i])
                          formData.append('prc_storage_location['+i+']', context.prcMcrItemSloc.storage_location[i])
                          // formData.append('prc_warehouse_no['+i+']', context.prcMcrItemSloc.warehouse_number[i])
                          // formData.append('prc_storage_type_code['+i+']', context.prcMcrItemSloc.storage_type[i])
                          formData.append('prc_bin_code['+i+']', context.prcMcrItemSloc.bin_location[i])
                          formData.append('prc_sloc_status['+i+']', context.prcMcrItemSloc.status[i])
                          formData.append('prc_sloc_del_flag['+i+']', context.prcMcrItemSloc.del_flag[i])
                      } else {
                          context.completeDataNotify('storage_location')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_sloc', '')
                  formData.append('prc_storage_location', '')
                  // formData.append('prc_warehouse_no', '')
                  // formData.append('prc_storage_type_code', '')
                  formData.append('prc_bin_code', '')
                  formData.append('prc_sloc_status', '')
                  formData.append('prc_sloc_del_flag', '')
                }

                // SALES
                    
                if (context.prcMcrItemSales.sales_org.length) {
                  for (var i=0; i<context.prcMcrItemSales.sales_org.length; i++) {
                      if (context.prcMcrItemSales.sales_org[i] != '' &&
                          context.prcMcrItemSales.distr_channel[i] != '' &&
                          context.prcMcrItemSales.sales_unit[i] != '' &&
                          context.prcMcrItemSales.division[i] != '' &&
                          context.prcMcrItemSales.tax_cat[i] != '' &&
                          context.prcMcrItemSales.tax_class[i] != '' &&
                          context.prcMcrItemSales.gen_item_cat_grp[i] != '' &&
                          context.prcMcrItemSales.account_assign_grp[i] != '' &&
                          context.prcMcrItemSales.item_cat_grp[i] != '' &&
                          context.prcMcrItemSales.trans_group[i] != '' &&
                          context.prcMcrItemSales.loading_group[i] != '' &&
                          context.prcMcrItemSales.profit_center[i] != '') {
                          
                          formData.append('prc_plant_sales['+i+']', context.prcMcrItemSales.sls_plant_code[i])
                          formData.append('prc_sales_org_code['+i+']', context.prcMcrItemSales.sales_org[i])
                          formData.append('prc_distr_channel_code['+i+']', context.prcMcrItemSales.distr_channel[i])
                          formData.append('prc_sales_unit['+i+']', context.prcMcrItemSales.sales_unit[i])
                          formData.append('prc_division_code['+i+']', context.prcMcrItemSales.division[i])
                          formData.append('prc_tax_cat_code['+i+']', 'MWST')
                          formData.append('prc_tax_class_code['+i+']', context.prcMcrItemSales.tax_class[i])
                          formData.append('prc_gen_item_cat_grp_code['+i+']', context.prcMcrItemSales.gen_item_cat_grp[i])
                          formData.append('prc_acc_ass_grp_code['+i+']', context.prcMcrItemSales.account_assign_grp[i])
                          formData.append('prc_item_cat_grp_code['+i+']', context.prcMcrItemSales.item_cat_grp[i])
                          formData.append('prc_trans_group_code['+i+']', context.prcMcrItemSales.trans_group[i])
                          formData.append('prc_loading_group_code['+i+']', context.prcMcrItemSales.loading_group[i])
                          formData.append('prc_profit_center_code['+i+']', context.prcMcrItemSales.profit_center[i])
                          formData.append('prc_sales_status['+i+']', context.prcMcrItemSales.status[i])
                          formData.append('prc_sales_del_flag['+i+']', context.prcMcrItemSales.del_flag[i])
                      } else {
                          context.completeDataNotify('sales')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_sales', '')
                  formData.append('prc_sales_org_code', '')
                  formData.append('prc_distr_channel_code', '')
                  formData.append('prc_sales_unit', '')
                  formData.append('prc_division_code', '')
                  formData.append('prc_tax_cat_code', '')
                  formData.append('prc_tax_class_code', '')
                  formData.append('prc_gen_item_cat_grp_code', '')
                  formData.append('prc_acc_ass_grp_code', '')
                  formData.append('prc_trans_group_code', '')
                  formData.append('prc_loading_group_code', '')
                  formData.append('prc_profit_center_code', '')
                  formData.append('prc_sales_status', '')
                  formData.append('prc_sales_del_flag', '')
                }
                    
                // MRP

                if (context.prcMcrItemMrp.plant_mrp.length ==  context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemMrp.plant_mrp.length; i++) {
                      if (context.prcMcrItemMrp.plant_mrp[i] != '' &&
                      	  context.prcMcrItemMrp.abc_indicator[i] != '' &&
                          context.prcMcrItemMrp.mrp_type[i] != '' &&
                          context.prcMcrItemMrp.mrp_controller[i] != '' &&
                          // context.prcMcrItemMrp.reorder_point[i] != '' &&
                          context.prcMcrItemMrp.lot_size[i] != '' &&
                          // context.prcMcrItemMrp.min[i] != '' &&
                          // context.prcMcrItemMrp.max[i] != '' &&
                          context.prcMcrItemMrp.procurement_type[i] != '') {

                          formData.append('prc_plant_mrp['+i+']', context.prcMcrItemMrp.plant_mrp[i])
                          formData.append('prc_abc_indicator_code['+i+']', context.prcMcrItemMrp.abc_indicator[i])
                          formData.append('prc_mrp_type_code['+i+']', context.prcMcrItemMrp.mrp_type[i])
                          formData.append('prc_mrp_controller_code['+i+']', context.prcMcrItemMrp.mrp_controller[i])
                          formData.append('prc_reorder_point['+i+']', context.prcMcrItemMrp.reorder_point[i])
                          formData.append('prc_lot_size_code['+i+']', context.prcMcrItemMrp.lot_size[i])
                          formData.append('prc_min_stock['+i+']', context.prcMcrItemMrp.min[i])
                          formData.append('prc_max_stock['+i+']', context.prcMcrItemMrp.max[i])
                          formData.append('prc_procurement_type_code['+i+']', context.prcMcrItemMrp.procurement_type[i])
                          formData.append('prc_special_proc_type_code['+i+']', context.prcMcrItemMrp.special_proc[i])
                          formData.append('prc_max_stock_mrp['+i+']', context.prcMcrItemMrp.max_stock[i])
                          formData.append('prc_fixed_lot['+i+']', context.prcMcrItemMrp.fixed_lot[i])
                          formData.append('prc_ord_costs['+i+']', context.prcMcrItemMrp.ord_costs[i])
                          formData.append('prc_mrp_status['+i+']', context.prcMcrItemMrp.status[i])
                          formData.append('prc_mrp_del_flag['+i+']', context.prcMcrItemMrp.del_flag[i])
                      } else {
                          context.completeDataNotify('mrp')
                          return false;
                      }
                  }
                } else {
                  // formData.append('prc_plant_mrp', '')
                  // formData.append('prc_abc_indicator_code', '')
                  // formData.append('prc_mrp_type_code', '')
                  // formData.append('prc_mrp_controller_code', '')
                  // formData.append('reorder_point', '')
                  // formData.append('prc_lot_size_code', '')
                  // formData.append('prc_min_stock', '')
                  // formData.append('prc_max_stock', '')
                  // formData.append('prc_procurement_type_code', '')
                  // formData.append('prc_special_proc_type_code', '')
                  // formData.append('prc_max_stock_mrp', '')
                  // formData.append('prc_fixed_lot', '')
                  // formData.append('prc_ord_costs', '')
                  // formData.append('prc_mrp_status', '')
                  // formData.append('prc_mrp_del_flag', '')
                  context.requiredTabNotify('mrp');
                  return false;
                }

                // ACCOUNTING
                    
                if (context.prcMcrItemAccounting.plant_accounting.length ==  context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemAccounting.valuation_class.length; i++) {
                      if (context.prcMcrItemAccounting.valuation_class[i] != '' &&
                          // context.prcMcrItemAccounting.valuation_category[i] != '' &&
                          // context.prcMcrItemAccounting.valuation_type[i] != '' &&
                          // context.prcMcrItemAccounting.price_control[i] != '' &&
                          context.prcMcrItemAccounting.price_determination[i] != '' &&
                          // context.prcMcrItemAccounting.standard_price[i] != '' &&
                          context.prcMcrItemAccounting.price_unit[i] != '' &&
                          context.prcMcrItemAccounting.price_reference[i] != '') {

                          formData.append('prc_plant_accounting['+i+']', context.prcMcrItemAccounting.plant_accounting[i])
                          formData.append('prc_valuation_class_code['+i+']', context.prcMcrItemAccounting.valuation_class[i])
                          formData.append('prc_valuation_category_code['+i+']', context.prcMcrItemAccounting.valuation_category[i])
                          formData.append('prc_valuation_type_code['+i+']', context.prcMcrItemAccounting.valuation_type[i])
                          formData.append('prc_price_control_code['+i+']', context.prcMcrItemAccounting.price_control[i])
                          formData.append('prc_price_determination['+i+']', context.prcMcrItemAccounting.price_determination[i])
                          formData.append('prc_standard_price['+i+']', (context.prcMcrItemAccounting.standard_price[i] == null) ? '' : context.prcMcrItemAccounting.standard_price[i])
                          formData.append('prc_price_unit['+i+']', context.prcMcrItemAccounting.price_unit[i])
                          formData.append('prc_price_reference['+i+']', context.prcMcrItemAccounting.price_reference[i])
                          formData.append('prc_accounting_status['+i+']', context.prcMcrItemAccounting.status[i])
                          formData.append('prc_accounting_del_flag['+i+']', context.prcMcrItemAccounting.del_flag[i])
                      } else {
                          context.completeDataNotify('accounting')
                          return false;
                      }
                  }
                } else {
                  // formData.append('prc_plant_accounting', '')
                  // formData.append('prc_valuation_class_code', '')
                  // formData.append('prc_valuation_category_code', '')
                  // formData.append('prc_valuation_type_code', '')
                  // formData.append('prc_price_control_code', '')
                  // formData.append('prc_price_determination', '')
                  // formData.append('prc_standard_price', '')
                  // formData.append('prc_price_unit', '')
                  // formData.append('prc_price_reference', '')
                  // formData.append('prc_accounting_status', '')
                  // formData.append('prc_accounting_del_flag', '')
                  context.requiredTabNotify('accounting');
                  return false;
                }

                // PURCHASING

                if (context.prcMcrItemPurchasing.plant_purchasing.length ==  context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemPurchasing.plant_purchasing.length; i++) {
                      if (context.prcMcrItemPurchasing.plant_purchasing[i] != '' &&
                          context.prcMcrItemPurchasing.purchasing_group[i] != ''
                          // context.prcMcrItemPurchasing.order_unit[i] != '' &&
                          // context.prcMcrItemPurchasing.planned_delivery[i] != ''
                          ) {

                          formData.append('prc_plant_purchasing['+i+']', context.prcMcrItemPurchasing.plant_purchasing[i])
                          formData.append('prc_purchasing_group_code['+i+']', context.prcMcrItemPurchasing.purchasing_group[i])
                          formData.append('prc_order_unit['+i+']', context.prcMcrItemPurchasing.order_unit[i])
                          formData.append('prc_planned_delivery['+i+']', context.prcMcrItemPurchasing.planned_delivery[i])
                          formData.append('prc_purchasing_status['+i+']', context.prcMcrItemPurchasing.status[i])
                          formData.append('prc_purchasing_del_flag['+i+']', context.prcMcrItemPurchasing.del_flag[i])
                      } else {
                          context.completeDataNotify('purchasing')
                          return false;
                      }
                  }
                } else {
                  // formData.append('prc_plant_purchasing', '')
                  // formData.append('prc_purchasing_group_code', '')
                  // formData.append('prc_order_unit', '')
                  // formData.append('prc_planned_delivery', '')
                  // formData.append('prc_purchasing_status', '')
                  // formData.append('prc_purchasing_del_flag', '')
                  context.requiredTabNotify('purchasing');
                  return false;
                }

                // COSTING

                if (context.prcMcrItemCosting.plant_costing.length) {
                  for (var i=0; i<context.prcMcrItemCosting.plant_costing.length; i++) {
                      if (context.prcMcrItemCosting.plant_costing[i] != '' &&
                          context.prcMcrItemCosting.overhead_group[i] != ''
                          ) {

                          formData.append('prc_plant_costing['+i+']', context.prcMcrItemCosting.plant_costing[i])
                          formData.append('prc_overhead_group['+i+']', context.prcMcrItemCosting.overhead_group[i])
                          formData.append('prc_costing_lot_size['+i+']', context.prcMcrItemCosting.costing_lot_size[i])
                          formData.append('prc_costing_status['+i+']', context.prcMcrItemCosting.status[i])
                          formData.append('prc_costing_del_flag['+i+']', context.prcMcrItemCosting.del_flag[i])
                      } else {
                          context.completeDataNotify('costing')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_costing', '')
                  formData.append('prc_overhead_group', '')
                  formData.append('prc_costing_lot_size', '')
                  formData.append('prc_costing_status', '')
                  formData.append('prc_costing_del_flag', '')
                }

                // EQUIPMENT
                    
            	if (context.prcMcrItemEquipment.plant_equipment_code.length) {
                  for (var i=0; i<context.prcMcrItemEquipment.plant_equipment_code.length; i++) {
                      if (context.prcMcrItemEquipment.plant_equipment_code[i] != '' &&
                          context.prcMcrItemEquipment.equipment_code[i] != '' &&
                          context.prcMcrItemEquipment.qty_installed[i] != '' &&
                          context.prcMcrItemEquipment.drawing_number[i] != '') {

                          formData.append('prc_plant_equipment['+i+']', context.prcMcrItemEquipment.plant_equipment_code[i])
                          formData.append('prc_equipment_code['+i+']', context.prcMcrItemEquipment.equipment_code[i])
                          formData.append('prc_qty_installed['+i+']', context.prcMcrItemEquipment.qty_installed[i])
                          formData.append('prc_drawing_number['+i+']', context.prcMcrItemEquipment.drawing_number[i])
                          formData.append('prc_equipment_del_flag['+i+']', context.prcMcrItemEquipment.del_flag[i])
                          formData.append('prc_equipment_status['+i+']', context.prcMcrItemEquipment.status[i])
                      } else {
                          context.completeDataNotify('equipment')
                          return false;
                      }
                  }
                } else {
                	if (context.input.prc_item_type_code == 'OEM' || context.input.prc_bom_non_bom == 'BOM') {
                		context.requiredTabNotify('equipment');
						return false;
                	} else {
						formData.append('prc_plant_equipment', '')
						formData.append('prc_equipment_code', '')
						formData.append('prc_qty_installed', '')
						formData.append('prc_drawing_number', '')
						formData.append('prc_equipment_del_flag', '')
						formData.append('prc_equipment_status', '')
                	}
                }

                // ATTACHMENT
                    
            	if (context.prcMcrItemAttachment.file_attachment_code.length) {
                  for (var i=0; i<context.prcMcrItemAttachment.file_attachment_code.length; i++) {
                      if (context.prcMcrItemAttachment.file_attachment_code.length > 0 &&
                          context.prcMcrItemAttachment.file_attachment_code[context.prcMcrItemAttachment.file_attachment_code.length-1] != '') {
                          formData.append('file_attachment_code['+i+']', context.prcMcrItemAttachment.file_attachment_code[i])
                      } else {
                          context.completeDataNotify('attachment')
                          return false;
                      }
                  }
                } else {
                	context.requiredTabNotify('attachment');
					return false;
                }

                // KEYWORD
                    
            	if (context.prcMcrItemKeyword.keyword.length) {
                  for (var i=0; i<context.prcMcrItemKeyword.keyword.length; i++) {
                      if (context.prcMcrItemKeyword.keyword[i] != '') {
                          formData.append('keyword['+i+']', context.prcMcrItemKeyword.keyword[i])
                      } else {
                          context.completeDataNotify('keyword')
                          return false;
                      }
                  }
                } else {
                  formData.append('keyword', '')
                }

                // NOTE

                if (context.input.note != '') {
            		formData.append('internal_note', context.input.note)
            	} else {
					formData.append('internal_note', '')
            	}

            	let api = "";
		        if (save == "add") {
					api = Api(context, draftList.createNewMcrItem(formData));
		        } else if (save == "edit") {
					api = Api(context, draftList.updateMcrItem(formData, context.input.id));
		        } else if (save == "process") {
		        	api = Api(context, mcrApproval.process(formData, context.input.id));
		        }

                this.form.btnSave.onLoading = true;
                
                
                api.onSuccess(function(response) {    
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
						horizontalAlign: "left"
                    });

                    if (context.formType == 'add') {
                    	context.$router.back();
                    } else {
                    	context.getDetailMcrItem();
                    	context.checkFormType();
			            context.getParameterMcr();
						context.checkDuplicateByMcrItemCode();
                    }
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.draftList = [];
                    }
                }).onFinish(function() {
                    context.form.btnSave.onLoading = false;
                })
                .call()

                // // debug formData
                // for (var pair of formData.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }
            },
            selectedCinCode(cin_code) {
              let context = this;

              context.charValue = [];

              Api(context, characteristicItemName.get_characteristic_value(null, cin_code, {per_page: 'none'}))
              .onSuccess(function(response) {
                response.data.data.data.data.forEach(function(item, index) {
                    context.charValue[index] = {
                        'code': item['cin_code'],
                        'value': item['characteristic_value']
                    };
                });
              })
              .onError(function(error) {
                if (error.response.status == 404) {
                  context.charValue = [];
                }
              })
              .call();
            },
            changeDrawingNumber(key) {
            	let context = this;

            	Api(context, drawingMaster.getAttachment(context.prcMcrItemEquipment.drawing_number[key])).onSuccess(function(response) {  
                    context.drawingAttachment[key] = response.data.data[0].file_name;
                    context.$forceUpdate()
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingAttachment[key] = [];
                    }
                }).call()
            },
            checkDuplicateByMcrItemCode() {
            	let context = this;

            	Api(context, checkDuplicate.checkDuplicateByMcrItemCode(context.mcrItemCode)).onSuccess(function(response) {  
                    context.dataDuplicate.by_description = response.data.data.byDescription;
                    context.dataDuplicate.by_part_number = response.data.data.byPartNumber;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dataDuplicate.by_description = [];
                        context.dataDuplicate.by_part_number = [];
                    }
                }).call()
            },
            getValue(queryString, cb) {
              var links = this.charValue;
              var results = queryString ? links.filter(this.createFilter(queryString)) : links;
              cb(results)
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            querySearchItemName(query) {
            	if (query.length > 3) {
            		clearTimeout(this.selectSearch.loadTimeout);             
	                let context = this;   

	                this.selectSearch.sitLoading = true;
					
	                this.selectSearch.loadTimeout = setTimeout(() => {
	                    Api(context, standardItemName.get(null, {per_page: 'none',item_name: query})).onSuccess(function(response) {
	                        context.standardItemName = response.data.data.data.data;    
	                        context.selectSearch.sitLoading = false;                                           
	                    }).onError(function(error) {
	                        context.standardItemName = [];
	                        context.selectSearch.sitLoading = false;
	                    }).call();             
	                }, 200)
            	}
            },
            querySearchGroupClass(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.ngcLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, natoGroupClass.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.natoGroupClass = response.data.data.data.data;
	                    context.selectSearch.ngcLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.natoGroupClass = [];
	                        context.selectSearch.ngcLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            changeGroupClass() {
            	let context = this;

            	Api(context, scopeOfSupply.sosByMGroup(context.input.prc_nsc)).onSuccess(function(response) {    
            		context.input.prc_sos_header = response.data.data[0].sos_header;

                    context.scopeOfSupply[0] = {
			          	sos_header: response.data.data[0].sos_header,
			          	sos_description: response.data.data[0].sos_description,
			        }
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.scopeOfSupply = [];
                    }
                })
                .call()
            },
            querySearchUom(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.uomLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.unitOfMeasure = response.data.data.data.data;
	                    context.selectSearch.uomLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.unitOfMeasure = [];
	                        context.selectSearch.uomLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchOrderUnit(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.orderUnitLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.orderUnit = response.data.data.data.data;
	                    context.selectSearch.orderUnitLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.orderUnit = [];
	                        context.selectSearch.orderUnitLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMtype(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.mtLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, materialType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.materialType = response.data.data.data.data;
	                    context.selectSearch.mtLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.materialType = [];
	                        context.selectSearch.mtLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchCtype(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.ctLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, catalogType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.catalogType = response.data.data.data.data;
	                    context.selectSearch.ctLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.catalogType = [];
	                        context.selectSearch.ctLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMgroup(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.mgLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, materialGroup.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.materialGroup = response.data.data.data.data;
	                    context.selectSearch.mgLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.materialGroup = [];
	                        context.selectSearch.mgLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchSos(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.sosLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, scopeOfSupply.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.scopeOfSupply = response.data.data.data.data;
	                    context.selectSearch.sosLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.scopeOfSupply = [];
	                        context.selectSearch.sosLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMancode(query) {
            	if (query.length > 4) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.mancodeLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, manufacture.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.masterManufacture = response.data.data.data.data;
		                    context.selectSearch.mancodeLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.masterManufacture = [];
		                        context.selectSearch.mancodeLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchPlant(query) {
            	if (query.length > 1) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.plantLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    if (context.mcrType == 'B4' || context.mcrType == 'A3') {
					    	Api(context, plantCode.materialPlantException(context.input.material_number, {search: query})).onSuccess(function(response) {  
			                    context.plantCode = response.data.data;
			                    context.selectSearch.plantLoading = false;
			                }).onError(function(error) {                    
			                    if (error.response.status == 404) {
			                        context.plantCode = [];
			                        context.selectSearch.plantLoading = false;
			                    }
			                })
			                .call()
					    } else {
					    	Api(context, plantCode.companyPlant({search: query})).onSuccess(function(response) {  
			                    context.plantCode = response.data.data;
			                    context.selectSearch.plantLoading = false;
			                }).onError(function(error) {                    
			                    if (error.response.status == 404) {
			                        context.plantCode = [];
			                        context.selectSearch.plantLoading = false;
			                    }
			                })
			                .call()
					    }     
					}, 200)
            	}
            },
            querySearchSloc(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.slocLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, locationCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.locationCode = response.data.data.data.data;
		                    context.selectSearch.slocLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.locationCode = [];
		                        context.selectSearch.slocLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchBinloc(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.binlocLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, binloc.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.binloc = response.data.data.data.data;
		                    context.selectSearch.binlocLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.binloc = [];
		                        context.selectSearch.binlocLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchProfitCenter(query) {
            	// if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.pcenterLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, profitCenter.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.profitCenter = response.data.data.data.data;
		                    context.selectSearch.pcenterLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.profitCenter = [];
		                        context.selectSearch.pcenterLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	// }
            },
     //        querySearchMrpController(query) {
     //        	// if (query.length > 2) {
     //        		clearTimeout(this.selectSearch.loadTimeout);             
					// let context = this;   

					// this.selectSearch.mrpControllerLoading = true;
					// this.selectSearch.loadTimeout = setTimeout(() => {
					//     Api(context, mrpController.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		   //                  context.mrpController = response.data.data.data.data;
		   //                  context.selectSearch.mrpControllerLoading = false;
		   //              }).onError(function(error) {                    
		   //                  if (error.response.status == 404) {
		   //                      context.mrpController = [];
		   //                      context.selectSearch.mrpControllerLoading = false;
		   //                  }
		   //              })
		   //              .call()        
					// }, 200)
     //        	// }
     //        },
     //        querySearchSpcProcType(query) {
     //        	// if (query.length > 2) {
     //        		clearTimeout(this.selectSearch.loadTimeout);             
					// let context = this;   

					// this.selectSearch.spcProcTypeLoading = true;
					// this.selectSearch.loadTimeout = setTimeout(() => {
					//     Api(context, specialProcurementType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		   //                  context.specialProcurementType = response.data.data.data.data;
		   //                  context.selectSearch.spcProcTypeLoading = false;
		   //              }).onError(function(error) {                    
		   //                  if (error.response.status == 404) {
		   //                      context.specialProcurementType = [];
		   //                      context.selectSearch.spcProcTypeLoading = false;
		   //                  }
		   //              })
		   //              .call()        
					// }, 200)
     //        	// }
     //        },
     //        querySearchValClass(query) {
     //        	// if (query.length > 2) {
     //        		clearTimeout(this.selectSearch.loadTimeout);             
					// let context = this;   

					// this.selectSearch.valClassLoading = true;
					// this.selectSearch.loadTimeout = setTimeout(() => {
					//     Api(context, valuationClass.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		   //                  context.valuationClass = response.data.data.data.data;
		   //                  context.selectSearch.valClassLoading = false;
		   //              }).onError(function(error) {                    
		   //                  if (error.response.status == 404) {
		   //                      context.valuationClass = [];
		   //                      context.selectSearch.valClassLoading = false;
		   //                  }
		   //              })
		   //              .call()        
					// }, 200)
     //        	// }
     //        },
            querySearchPurGroup(query) {
            	// if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.purGroupLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, catalogType.getPurchaseByCompany()).onSuccess(function(response) {  
		                    context.purchasingGroup = response.data.data;
		                    context.selectSearch.purGroupLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.purchasingGroup = [];
		                        context.selectSearch.purGroupLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	// }
            },
            querySearchEquipment(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.equipmentLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, equipmentCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.equipmentCode = response.data.data.data.data;
		                    context.selectSearch.equipmentLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.equipmentCode = [];
		                        context.selectSearch.equipmentLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchDrawingNumber(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.drawingNumLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, drawingMaster.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.drawingMaster = response.data.data.data.data;
		                    context.selectSearch.drawingNumLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.drawingMaster = [];
		                        context.selectSearch.drawingNumLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchMaterialNumber(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.matnumLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, materialNumber.get({search: query})).onSuccess(function(response) {  
		                    context.materialNumber = response.data.data;
		                    context.selectSearch.matnumLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.materialNumber = [];
		                        context.selectSearch.matnumLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: this.tt(title) }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            requiredTabNotify(tabName) {
            	this.$notify({
					message: 'Tab ' +tabName+ ' is required',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            smartcatAttachment() {
            	this.modalSmartcatAttachment = true;
            },
            checkDuplicate() {
            	this.modalCheckDuplicate = true;
            },
        }   
    };
</script>

<!-- ///// TYPE B2 /////
Create
1. Semua tombol add, delete muncul 

Edit
1. Semua tombol add,delete muncul
2. Get all data from table prc, getDetailMcrItem() 
--> 

<!-- ///// TYPE B3 /////
Create
1. Plant disable, tidak bisa di edit
2. Tombol delete di hide, tidak bisa delete
3. tombol delete hanya muncul di tab yang bisa di add dan hanya di item yang statusnya "new", kecuali attachment dan keyword

Edit
1. Plant disable, tidak bisa edit di edit
2. Tombol delete di hide, tidak bisa delete
3. Get all data from table prc dan jangan jalankan function changeMaterialNumber()
4. tombol delete hanya muncul di tab yang bisa di add dan hanya di item yang statusnya "new", kecuali attachment dan keyword
--> 


<!-- ///// TYPE APPROVAL PROCESS A1 /////
1. Load data from mcr_item, mcr_item_* untuk contekan di view A1 dan jadi default value di B2 (kondisi bila belum di proses)
2. Load data from table prc getDetailMcrItem(), untuk loop data yang sudah di proses
3. Semua tombol add, delete muncul
-->















